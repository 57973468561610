import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import Spinners, { Alerts } from "../../Components/Commons/Common";
import React, { useState } from "react";
import { BiSolidUser, BiRename } from "react-icons/bi";
import { AiFillSave } from "react-icons/ai";
import { RegisterUser } from "../../Backend/api";
import { Link, useNavigate } from "react-router-dom";

export default function Registration()

{
  const registerUser = RegisterUser();
  
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [ErrorConnexion, setErrorConnexion] = useState("");
  const [Alerte, setAlerte] = useState({
    State: false,
    Title: "",
    Type: "",
    Message: "",
  });

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false)
    {
        event.stopPropagation();
        setValidated(true);
    } else {
        registerUser.mutate({
          email: event.target.Email.value,
          nom: event.target.Nom.value,
          prenom: event.target.Prenom.value,
        });
    }
  };

  function onFermerAlert()
{
    setAlerte({ State: false });
  }

  if (registerUser.isError)
  {
    if (registerUser.error.response.data)
  {
      let m = "";
      if (registerUser.error.response.status === 401)
{
        let email = registerUser.error.response.data.errors["email"];
        if (email)
{
          for (let i = 0; i < email.length; ++i)
{
            m = m + email[i] + "\n";
          }
        }

        let nom = registerUser.error.response.data.errors["nom"];
        if (nom)
{
          for (let i = 0; i < nom.length; ++i)
{
            m = m + nom[i] + "\n";
          }
        }

        let prenom = registerUser.error.response.data.errors["prenom"];
        if (prenom)
{
          for (let i = 0; i < prenom.length; ++i)
{
            m = m + prenom[i] + "\n";
          }
        }
      } else if (registerUser.error.response.status === 404)
{
        m = registerUser.error.response.data.message;
      }
      setErrorConnexion(
        "Code: " +
          registerUser.error.response.status +
          " - " +
          registerUser.error.response.data.message
      );
      setAlerte({
        State: true,
        Title:  "Erreur - Code: " + registerUser.error.response.status,
        Type: "ERROR",
        Message: m,
      });
    }
    registerUser.reset();
  } else if (registerUser.isSuccess)
{
    registerUser.reset();
    navigate("/Confirmation", {
      state: {
        message: "Votre Registration a été enregistrée avec succès !",
        description:
          "Un courriel contenant votre mot de passe temporaire a été envoyé",
      },
    });
  }

  if (registerUser.isLoading)
  {
    return (
      <Spinners
        Message={"Enregistrement en cours..., Veuillez patienter s'il-vous-plaît."}
      />
    );
  }

  return (
    <>
      <div className="row d-flex justify-content-center mt-4">
        <div className="col-9 col-xs-8 col-sm-8 col-md-6 col-lg-5 col-xl-4">
          <div
            className="ombre-style d-flex flex-column justify-content-center align-items-center border border-2 border-danger px-3 px-lg-5 pb-4"
            style={{ borderRadius: "25px" }}
          >
            <img
              src="./assets/images/logo.svg"
              alt="logo"
              width="25%"
              className="my-2"
            />
            <h4 className="fw-bold text-center mb-0 fs-5 mt-2 mt-lg-0">
              Enregistrement d'un nouvel utilisateur par l'administrateur
            </h4>
            <p className="text-secondary text-center">
              (L'administrateur inscrit les informations de son employé et un
              courriel lui sera envoyé directement.)
            </p>
            <Alert
              key="danger"
              variant="danger"
              className={
                ErrorConnexion !== "" ? "d-block py-0 mb-2 mb-lg-3" : "d-none"
              }
              style={{ fontSize: "12px" }}
            >
              {ErrorConnexion}
            </Alert>
            <Form
              id="form-connexion"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className="w-100 d-flex flex-column align-items-center mb-2"
            >
              <Form.Group controlId="Email" className="mb-2 mb-lg-3 px-0 w-100">
                <InputGroup hasValidation>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="bg-danger border border-1 border-danger"
                  >
                    <BiSolidUser className="fs-3 my-0 text-light" />
                  </InputGroup.Text>
                  <Form.Control
                    className="border border-1 border-danger"
                    type="email"
                    placeholder="Courriel"
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="mt-0"
                    style={{ fontSize: "0.7rem" }}
                  >
                    Le l'adresse courriel est requis,
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="Nom" className="mb-2 mb-lg-3 px-0 w-100">
                <InputGroup hasValidation>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="bg-danger border border-1 border-danger"
                  >
                    <BiRename className="fs-3 my-0 text-light" />
                  </InputGroup.Text>
                  <Form.Control
                    className="border border-1 border-danger"
                    type="text"
                    placeholder="Nom"
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="mt-0"
                    style={{ fontSize: "0.7rem" }}
                  >
                    Le champ Nom est obligatoire.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="Prenom" className="px-0 w-100">
                <InputGroup hasValidation>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="bg-danger border border-1 border-danger"
                  >
                    <BiRename className="fs-3 my-0 text-light" />
                  </InputGroup.Text>
                  <Form.Control
                    className="border border-1 border-danger"
                    type="text"
                    placeholder="Prénom"
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="mt-0"
                    style={{ fontSize: "0.7rem" }}
                  >
                    Le mot de passe est obligatoire et doit contenir minimum 8
                    caractères et au moins 1 lettre minuscule et majuscule, 1
                    chiffre et 1 symbole'
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <div className="d-flex flex-column justify-content-center mt-3 mt-lg-4">
                <Button
                  type="submit"
                  className="btn btn-danger mx-5 d-flex align-items-center justify-content-center"
                >
                  <AiFillSave className="fs-2 me-2" />
                  Enregistrer
                </Button>
              </div>
              <Link
                to="/"
                className="text-center mt-3"
                style={{ textUnderlineOffset: "5px" }}
              >
                Retour à la page de connexion
              </Link>
            </Form>
          </div>
        </div>
      </div>

      <Alerts
        State={Alerte.State}
        Type={Alerte.Type}
        Title={Alerte.Title}
        Message={Alerte.Message}
        onFermer={onFermerAlert}
        Duree={25}
      />
    </>
  );
}
