import { useRef, useState, useEffect, useContext } from 'react';
import Stepper from 'react-stepper-horizontal';
import { Button, Form, InputGroup } from "react-bootstrap";
import { GrUserAdmin } from "react-icons/gr";
import { MdEmail, MdChecklist, MdOutlineAddHomeWork } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { SlEnvolopeLetter } from "react-icons/sl";
import { FaMapMarkerAlt, FaCity } from "react-icons/fa";
import { IoArrowBackCircle, IoArrowForwardCircle } from "react-icons/io5";
import { CiDiscount1 } from "react-icons/ci";
import { FaBuildingUser, FaComputer } from "react-icons/fa6";
import { BiRename } from "react-icons/bi";
import Spinners, { Height100, Alerts, getError } from "../../Components/Commons/Common";
import { RegisterCompany } from '../../Backend/api';
import { sessionContext } from "../../App";
import { useNavigate } from 'react-router-dom';


function Company({activeStep, steps, values, onClickLast, onClickNext}) {
    const [validated, setValidated] = useState(false);
    let [InputCode, setInputCode] = useState("");
    const [valuesCompany, setvaluesCompany] = useState(values);
    const inputFileRef = useRef(null);
    const [refList, setRefList] = useState();
    let rList = useRef();
    
    rList= Height100(rList, 50);

    useEffect(()=>{
        setRefList(rList.current);

        if (values) {
            setInputCode(values.discount);
            inputFileRef.current.files= values.logo;
        }
    }, [values, InputCode, rList, setRefList])

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false)
        {
            event.stopPropagation();
            setValidated(true);
        } else {
            let values= {companyName: event.target.nom.value,
                address: event.target.adresse.value,
                city: event.target.ville.value,
                postalCode: event.target.code_postal.value,
                phone: event.target.telephone.value,
                webSite: event.target.site_web.value,
                discount: event.target.remise.value,
                logo: event.target.logo.files};
            setvaluesCompany(values);
            ++activeStep;
            onClickNext(activeStep, values);
        }
    };

    function handleKeyUp(event)
    {
        if (event.target.value.length <= 3)
        {
            setInputCode(event.target.value);
        }
    }

    return (
        <div className="row d-flex justify-content-center">
            <div className="col-11">
                <div ref={rList} className="ombre-style d-flex flex-column justify-content-evenly align-items-center border border-2 border-danger px-3 px-lg-5 pb-4" style={{ borderRadius: "25px" }}>                    
                    <h2 className="fw-bold text-center my-4"><MdOutlineAddHomeWork className='me-2'/>Ajout d'une nouvelle entreprise</h2>
                    <Form className="w-100 d-flex flex-column align-items-center mb-2" noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className='d-flex flex-lg-row flex-column justify-content-center w-100'>
                            <div className='mx-2'>
                                <Form.Group controlId="nom" className="mb-2 mb-lg-3 px-0">
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger" >
                                            <FaBuildingUser className="fs-3 my-0 text-light" />
                                        </InputGroup.Text>
                                        <Form.Control defaultValue={valuesCompany?valuesCompany.companyName:''} className="border border-1 border-danger" type="text" placeholder="Nom de l'entreprise" aria-describedby="inputGroupPrepend" required />
                                        <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} >
                                            Le champ nom est obligatoire.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group controlId="adresse" className="mb-2 mb-lg-3 px-0">
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger">
                                            <FaMapMarkerAlt className="fs-3 my-0 text-light" />
                                        </InputGroup.Text>
                                        <Form.Control defaultValue={valuesCompany?valuesCompany.address:''} className="border border-1 border-danger" type="tel" placeholder="Adresse" aria-describedby="inputGroupPrepend" required/>
                                        <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} >
                                            L'adresse est requise
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <div className="d-flex flex-column flex-lg-row">
                                    <Form.Group controlId="ville" className="mb-2 mb-lg-3 me-0 me-lg-2 px-0">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger" >
                                                <FaCity className="fs-3 my-0 text-light" />
                                            </InputGroup.Text>
                                            <Form.Select className="border border-1 border-danger" placeholder="Ville" aria-describedby="inputGroupPrepend" required >
                                                <option disabled>Selectionner la ville ...</option>
                                                <option value="1">Saint Jean-sur-Richelieu</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} >
                                                Le nom de la ville est requis
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group controlId="code_postal" className="mb-2 mb-lg-3 px-0" >
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger" >
                                                <SlEnvolopeLetter className="fs-3 my-0 text-light" />
                                            </InputGroup.Text>
                                            <Form.Control defaultValue={valuesCompany?valuesCompany.postalCode:''} className="border border-1 border-danger" type="text" placeholder="Code postal" aria-describedby="inputGroupPrepend" required />
                                            <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} >
                                                Le code postal est requis
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='mx-2'>
                                <Form.Group controlId="site_web" className="mb-2 mb-lg-3 px-0" >
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger" >
                                            <FaComputer className="fs-3 my-0 text-light" />
                                        </InputGroup.Text>
                                        <Form.Control defaultValue={valuesCompany?valuesCompany.webSite:''} className="border border-1 border-danger" type="text" placeholder="Site Web" aria-describedby="inputGroupPrepend" required/>
                                        <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }}>
                                            L'adresse web est requise
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                <div className="d-flex flex-column flex-lg-row">
                                    <Form.Group controlId="telephone" className="mb-2 me-0 me-lg-2 mb-lg-3 px-0" >
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger">
                                                <FiPhoneCall className="fs-3 my-0 text-light" />
                                            </InputGroup.Text>
                                            <Form.Control defaultValue={valuesCompany?valuesCompany.phone:''} className="border border-1 border-danger" type="tel" placeholder="Téléphone" aria-describedby="inputGroupPrepend" required/>
                                            <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} >
                                                Le numéro de téléphone est requis
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group controlId="remise" className="mb-2 mb-lg-3 px-0" >
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger">
                                                <CiDiscount1 className="fs-3 my-0 text-light" />
                                            </InputGroup.Text>
                                            <Form.Control className="border border-1 border-danger" type="number" maxLength={3} placeholder="Remise" 
                                                value={InputCode} onChange={(event) => handleKeyUp(event)}
                                                aria-describedby="inputGroupPrepend" required />
                                            <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} >
                                                La remise est requise
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </div>

                                <Form.Group className="mb-2 mb-lg-3 px-0" controlId="logo">
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="inputGroupPrepend" className="px-2 bg-danger rounded-0 border border-1 border-danger rounded-start" >
                                            <span className="fs-6 my-0 text-light">Logo</span>
                                        </InputGroup.Text>
                                        <Form.Control ref= {inputFileRef} className="border border-1 border-danger" type="file" placeholder="Logo" aria-describedby="inputGroupPrepend"/>
                                        <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} >
                                            Le Logo est requise
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="d-flex flex-column justify-content-center mt-4 w-100">
                            <ButtonLastNext activeStep= {activeStep} steps= {steps} onClickLast={onClickLast}/>
                        </div>
                    </Form>
                </div>
            </div>
      </div>
    )
}
  
function User({activeStep, steps, values, onClickLast, onClickNext}) {
    const [validated, setValidated] = useState(false);
    const [valuesUser, setvaluesUser] = useState(values);
    const inputFileRef = useRef(null);
    const [refList, setRefList] = useState();
    let rList = useRef();
    
    rList= Height100(rList, 50);

    useEffect(()=>{
        setRefList(rList.current);    
    }, [rList, setRefList]);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false)
        {
            event.stopPropagation();
            setValidated(true);
        } else {
            let values= {email: event.target.email.value,
                lastName: event.target.lastName.value,
                firstName: event.target.firstName.value}
            setvaluesUser(values);
            ++activeStep;
            onClickNext(activeStep, values);       
        }
    };
    
    return (
        <>
            <div className="row d-flex justify-content-center">
                <div className="col-11">
                    <div ref={rList} className="row ombre-style d-flex flex-column justify-content-evenly align-items-center border border-2 border-danger px-3 px-lg-5 pb-4" style={{ borderRadius: "25px" }}>            
                        <h2 className="fw-bold text-center my-4"><GrUserAdmin className='me-2'/>Ajout d'un nouvel Administrateur</h2>
                        
                        <Form id="form-connexion" noValidate validated={validated} onSubmit={handleSubmit} className="col-11 col-md-8 col-lg-6 col-xl-5 d-flex flex-column align-items-center mb-2" >
                            <Form.Group controlId="email" className="mb-2 mb-lg-3 px-0 w-100">
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger" >
                                        <MdEmail className="fs-3 my-0 text-light" />
                                    </InputGroup.Text>
                                    <Form.Control defaultValue={valuesUser?valuesUser.email:''} className="border border-1 border-danger" type="email" placeholder="Courriel" aria-describedby="inputGroupPrepend" required/>
                                    <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} > Le l'adresse courriel est requis, </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group> 
                            <Form.Group controlId="lastName" className="mb-2 mb-lg-3 px-0 w-100">
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger" >
                                        <BiRename className="fs-3 my-0 text-light" />
                                    </InputGroup.Text>
                                    <Form.Control defaultValue={valuesUser?valuesUser.lastName:''} className="border border-1 border-danger" type="text" placeholder="Nom" aria-describedby="inputGroupPrepend" required />
                                    <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} > Le champ Nom est requis. </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="firstName" className="px-0 w-100">
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger" >
                                        <BiRename className="fs-3 my-0 text-light" />
                                    </InputGroup.Text>
                                    <Form.Control defaultValue={valuesUser?valuesUser.firstName:''} className="border border-1 border-danger" type="text" placeholder="Prénom" aria-describedby="inputGroupPrepend" required/>
                                    <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} > Le champ Prenom est requis. </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <div className="d-flex flex-column justify-content-center  mt-4 w-100">
                                <ButtonLastNext activeStep= {activeStep} steps= {steps} onClickLast={onClickLast}/>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

function Confirmation({activeStep, steps, values, onClickLast, onClickNext}) {
    const [valuesCompany, setvaluesCompany] = useState(values.valuesCompany);
    const [valuesUser, setvaluesUser] = useState(values.valuesUser);
    const inputFileRef = useRef(null);
    const [refList, setRefList] = useState();
    let rList = useRef();
    
    rList= Height100(rList, 50);

    useEffect(()=>{
        setRefList(rList.current);  
    }, [rList, setRefList]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setvaluesUser(values);
        ++activeStep;
        onClickNext(activeStep, values);       
    };

    return (
        <div className="row d-flex justify-content-center">
            <div className="col-11">
                <div ref={rList} className="ombre-style d-flex flex-column justify-content-evenly align-items-center border border-2 border-danger px-3 px-lg-5 pb-4" style={{ borderRadius: "25px" }}>                    
                        <h2 className="fw-bold text-center my-4"><MdChecklist className='me-2'/>Confirmation (resumé)</h2>
                        <div className="overflow-x-hidden overflow-y-scroll w-100">
                            <div className='d-flex flex-column flex-lg-row align-items-start align-items-lg-start justify-content-evenly w-100' style={{height: "100%"}}>
                                <div className='d-flex flex-column justify-content-center'>
                                    <h4 className="fw-bold text-center pb-0"><MdOutlineAddHomeWork className='me-2'/>Entreprise</h4>
                                    <Form.Group controlId="nom" className="mb-2 mb-lg-3 px-0">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger rounded-0 rounded-start" >
                                                <FaBuildingUser className="fs-3 my-0 text-light" />
                                            </InputGroup.Text>
                                            <Form.Control disabled defaultValue={valuesCompany?valuesCompany.companyName:''} className="border border-1 border-danger" type="text" placeholder="Nom de l'entreprise" aria-describedby="inputGroupPrepend"/>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group controlId="adresse" className="mb-2 mb-lg-3 px-0">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger rounded-0 rounded-start">
                                                <FaMapMarkerAlt className="fs-3 my-0 text-light" />
                                            </InputGroup.Text>
                                            <Form.Control defaultValue={valuesCompany?valuesCompany.address:''} className="border border-1 border-danger" type="tel" placeholder="Adresse" aria-describedby="inputGroupPrepend" disabled/>
                                        </InputGroup>
                                    </Form.Group>
                                    <div className="d-flex flex-column flex-lg-row">
                                        <Form.Group controlId="ville" className="mb-2 mb-lg-3 me-0 me-lg-2 px-0">
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger rounded-0 rounded-start" >
                                                    <FaCity className="fs-3 my-0 text-light" />
                                                </InputGroup.Text>
                                                <Form.Select className="border border-1 border-danger" placeholder="Ville" aria-describedby="inputGroupPrepend" disabled >
                                                    <option disabled>Selectionner la ville ...</option>
                                                    <option value="1">Saint Jean-sur-Richelieu</option>
                                                </Form.Select>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group controlId="code_postal" className="mb-2 mb-lg-3 px-0" >
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger rounded-0 rounded-start" >
                                                    <SlEnvolopeLetter className="fs-3 my-0 text-light" />
                                                </InputGroup.Text>
                                                <Form.Control defaultValue={valuesCompany?valuesCompany.postalCode:''} className="border border-1 border-danger" type="text" placeholder="Code postal" aria-describedby="inputGroupPrepend" disabled />
                                            </InputGroup>
                                        </Form.Group>
                                    </div>
                                    <Form.Group controlId="site_web" className="mb-2 mb-lg-3 px-0" >
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger rounded-0 rounded-start" >
                                                <FaComputer className="fs-3 my-0 text-light" />
                                            </InputGroup.Text>
                                            <Form.Control defaultValue={valuesCompany?valuesCompany.webSite:''} className="border border-1 border-danger" type="text" placeholder="Site Web" aria-describedby="inputGroupPrepend" disabled/>
                                        </InputGroup>
                                    </Form.Group>
                                    <div className="d-flex flex-column flex-lg-row">
                                        <Form.Group controlId="telephone" className="mb-2 me-0 me-lg-2 mb-lg-3 px-0">
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger rounded-0 rounded-start">
                                                    <FiPhoneCall className="fs-3 my-0 text-light" />
                                                </InputGroup.Text>
                                                <Form.Control disabled defaultValue={valuesCompany?valuesCompany.phone:''} className="border border-1 border-danger" type="tel" placeholder="Téléphone" aria-describedby="inputGroupPrepend"/>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group controlId="remise" className="mb-2 mb-lg-3 px-0" >
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger rounded-0 rounded-start">
                                                    <CiDiscount1 className="fs-3 my-0 text-light" />
                                                </InputGroup.Text>
                                                <Form.Control defaultValue={valuesCompany?valuesCompany.discount:''} className="border border-1 border-danger" type="text" placeholder="Remise" aria-describedby="inputGroupPrepend" disabled/>
                                            </InputGroup>
                                        </Form.Group>
                                    </div>
                                    <Form.Group className="mb-2 mb-lg-3 px-0" controlId="logo">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="px-2 bg-danger rounded-0 border border-1 border-danger rounded-start" >
                                                <span className="fs-6 my-0 text-light">Logo</span>
                                            </InputGroup.Text>
                                            <Form.Control defaultValue={valuesCompany.logo.length > 0?valuesCompany.logo[0].name:''} className="border border-1 border-danger" type="text" placeholder="Logo" aria-describedby="inputGroupPrepend" disabled/>
                                        </InputGroup>
                                    </Form.Group>
                                </div>

                                <div className='d-none d-lg-block border border-2 border-danger' style={{height: "35vh", minHeight: "100%"}}></div>

                                <div className='d-flex flex-column justify-content-center mt-4 mt-lg-0'>
                                    <h4 className="fw-bold text-center pb-0"><GrUserAdmin className='me-2'/>Administrateur</h4>
                                    <Form.Group controlId="email" className="mb-2 mb-lg-3 px-0">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger rounded-0 rounded-start" >
                                                <MdEmail className="fs-3 my-0 text-light" />
                                            </InputGroup.Text>
                                            <Form.Control defaultValue={valuesUser?valuesUser.email:''} className="border border-1 border-danger" type="email" placeholder="Courriel" aria-describedby="inputGroupPrepend" disabled/>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group controlId="lastName" className="mb-2 mb-lg-3 px-0">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger rounded-0 rounded-start" >
                                                <BiRename className="fs-3 my-0 text-light" />
                                            </InputGroup.Text>
                                            <Form.Control defaultValue={valuesUser?valuesUser.lastName:''} className="border border-1 border-danger" type="text" placeholder="Nom" aria-describedby="inputGroupPrepend" disabled />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group controlId="firstName" className="px-0">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger rounded-0 rounded-start" >
                                                <BiRename className="fs-3 my-0 text-light" />
                                            </InputGroup.Text>
                                            <Form.Control defaultValue={valuesUser?valuesUser.firstName:''} className="border border-1 border-danger" type="text" placeholder="Prénom" aria-describedby="inputGroupPrepend" disabled/>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group className="mb-2 mb-lg-3 px-0" controlId="logo" style={{visibility:"hidden"}}>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="px-2 bg-danger rounded-0 border border-1 border-danger rounded-start" >
                                                <span className="fs-6 my-0 text-light">Logo</span>
                                            </InputGroup.Text>
                                            <Form.Control className="border border-1 border-danger" type="file" placeholder="Logo" aria-describedby="inputGroupPrepend" disabled/>
                                        </InputGroup>
                                    </Form.Group>

                                    <div className="d-flex flex-column flex-lg-row" style={{visibility:"hidden"}}>
                                        <Form.Group controlId="ville" className="mb-2 mb-lg-3 me-0 me-lg-2 px-0">
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger rounded-0 rounded-start" >
                                                    <FaCity className="fs-3 my-0 text-light" />
                                                </InputGroup.Text>
                                                <Form.Select className="border border-1 border-danger" placeholder="Ville" aria-describedby="inputGroupPrepend" disabled >
                                                    <option disabled>Selectionner la ville ...</option>
                                                    <option value="1">Saint Jean-sur-Richelieu</option>
                                                </Form.Select>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group controlId="code_postal" className="mb-2 mb-lg-3 px-0" >
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger rounded-0 rounded-start" >
                                                    <SlEnvolopeLetter className="fs-3 my-0 text-light" />
                                                </InputGroup.Text>
                                                <Form.Control defaultValue={valuesCompany?valuesCompany.postalCode:''} className="border border-1 border-danger" type="text" placeholder="Code postal" aria-describedby="inputGroupPrepend" disabled />
                                            </InputGroup>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Form onSubmit={handleSubmit} className='w-100'>
                            <div className="d-flex flex-column justify-content-center mt-4 w-100">
                                <ButtonLastNext activeStep= {activeStep} steps= {steps} onClickLast={onClickLast}/>
                            </div>
                        </Form>
                </div>
            </div>
      </div>
    );
}
function Save() {
    return <h2>Booking is Saved</h2>;
}

function ButtonLastNext({activeStep, steps, onClickLast}) {
    return (
        <div className='w-100 d-flex justify-content-evenly'>
            {(activeStep !== 0 && activeStep !== steps.length) && <Button className="btn btn-primary d-flex align-items-center py-1" onClick={ () => {--activeStep; onClickLast(activeStep) }}><IoArrowBackCircle className='me-2 fs-3'/>Précédent</Button>}
            {activeStep !== steps.length && <Button type="submit" className={activeStep === steps.length-1 ? "btn d-flex align-items-center py-1 btn-danger":"btn d-flex align-items-center py-1  btn-primary"}>{activeStep === steps.length-1 ? 'Enregistrer' : 'Suivant'}<IoArrowForwardCircle className='ms-2 fs-3'/></Button>}
        </div>
    )
}

export default function AddCompany() {
    const $primary= "#35435F";
    const $danger= "#CC1F4A";

    const [ activeStep, setActiveStep ] = useState(0);
    const [values, setValues] = useState();
    const [valuesCompany, setvaluesCompany] = useState({companyName: '', phone: '', address: '', city: '', postalCode: '', webSite: '', discount: 0, logo: null});
    const [valuesUser, setvaluesUser] = useState({email: '', lastName: '', firstName: ''});
    const [allValues, setAllValues] = useState({valuesCompany: {}, valuesUser: {}});
    const [session, setSession] = useContext(sessionContext);
    const navigate = useNavigate();
    const registerCompany = RegisterCompany();
    const [Alert, setAlert] = useState({ State: false, Title: "", Type: "", Message: "", });
    
    const steps = [
        {   title: <span className="fw-bold d-flex flex-column align-items-center"><MdOutlineAddHomeWork className='fs-4'/>Entreprise</span>,
            href: '#',
            onClick: (e) => { 
                e.preventDefault()
                setActiveStep(0);
                setValues(valuesCompany);
            }
        },
        {   title: <span className="fw-bold d-flex flex-column align-items-center"><GrUserAdmin className='fs-4'/>Administrateur</span>,
            href: '#',
            onClick: (e) => { 
                e.preventDefault()
                setActiveStep(1);
                setValues(valuesUser);
            } 
        },
        {   title: <span className="fw-bold d-flex flex-column align-items-center"><MdChecklist className='fs-4'/>Confirmation</span>, 
            href: '#',
            onClick: (e) => { 
                e.preventDefault()
                setActiveStep(2);
                setValues(allValues);
            } 
        },
    ];
    
    function getSectionComponent(activeStep, steps, values, onClickLast, onClickNext) {
        switch(activeStep) {
          case 0: return <Company activeStep= {activeStep} steps={steps} values={values} onClickLast={onClickLast} onClickNext={onClickNext}/>;
          case 1: return <User activeStep= {activeStep} steps={steps} values={values} onClickLast={onClickLast} onClickNext={onClickNext}/>;
          case 2: return <Confirmation activeStep= {activeStep} steps={steps} values={values} onClickLast={onClickLast} onClickNext={onClickNext}/>;
          case 3: return <Save/>;
          default: return null;
        }
    }

    function onClickLast(activeStep) {        
        setActiveStep(activeStep);
        switch(activeStep) {
            case 0: 
                setValues(valuesCompany);
                break;
            case 1: 
                setValues(valuesUser);
                break;
            case 2: 
                setValues(allValues);
                break;
            case 3: 
                break;
            default: 
                break;
        }
    }

    function onClickNext(activeStep, values) {
        setActiveStep(activeStep);
        switch(activeStep) {
            case 0: 
                break;
            case 1: 
                setvaluesCompany(values);
                if (valuesUser.email !=='' || valuesUser.nom !=='' || valuesUser.prenom !=='') {
                    setValues(valuesUser);
                }
                break;
            case 2: 
                setvaluesUser(values);
                setAllValues({valuesCompany: valuesCompany, valuesUser: valuesUser});

                setValues({valuesCompany: valuesCompany, valuesUser: values});
                break;
            case 3:
                registerCompany.mutate({nom: values.valuesCompany.companyName, adresse: values.valuesCompany.address, ville: values.valuesCompany.city, code_postal: values.valuesCompany.postalCode, telephone: values.valuesCompany.phone,
                                        site_web: values.valuesCompany.webSite, remise: values.valuesCompany.discount, logo: values.valuesCompany.logo.length >0?values.valuesCompany.logo[0].name:'',
                                        email: values.valuesUser.email, firstName: values.valuesUser.firstName, lastName: values.valuesUser.lastName, token: session.Token});
                break;
            default: 
                break;
        }
    }

    function onFermerAlert()
    {
        setAlert({ State: false });
    }

    if (registerCompany.isError)
    {
        let m = "";
        if (registerCompany.error.response.data.data) {
            m = m + getError(registerCompany, 'email');
            m = m + getError(registerCompany, 'name');
            m = m + getError(registerCompany, 'website');
        }

        registerCompany.reset();
        setActiveStep(2);
        setAlert({
            State: true,
            Title:  "Erreur - Code: " + registerCompany.error.response.status,
            Type: "ERROR",
            Message: registerCompany.error.response.data.message + "\n" + m,
        });
    }

    if (registerCompany.isSuccess)
    {
        registerCompany.reset();
        navigate("/Confirmation", {
            state: {
                message: "L'entreprise a été enregistrée avec succès !",
                description: "Un courriel contenant le mot de passe temporaire de l'administrateur a été envoyé",
                Logged: session.Logged,
                linkReturn: "/CompaniesList",
                textReturn: "Retour à la page liste entreprise"
            },
        });
    }

    if (registerCompany.isLoading)
    {
        return (
            <Spinners Message={ "Enregistrement de l'entreprise en cours..., Veuillez patienter s'il vous plait."} />
        );
    }

    return (
        <div>
            <Stepper steps={steps} activeStep={activeStep} 
            activeColor= {$primary}
            activeTitleColor={$primary}
            
            defaultColor="#eeeeee"
            defaultTitleColor="#bbbbbb"

            completeColor={$danger}
            completeTitleColor={$danger}
            
            circleFontColor="#ffffff"
            completeBarColor={$danger}/>
            
            <div style={{padding: '20px'}}>
                { getSectionComponent(activeStep, steps, values, onClickLast, onClickNext)  }
            </div>

            <Alerts State={Alert.State} Type={Alert.Type} Title={Alert.Title} Message={Alert.Message} onClose={onFermerAlert} Duree={25}/>
        </div>
    );
}