import "./Styles.css";
import React, { useContext, useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import { SearchUser } from "../../Backend/api";
import { sessionContext } from "../../App";
 
export default function UserDetails()
{
  const params = useLocation();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    id: "",
    lastName: "",
    firstName: "",
    email: "",
  });
  const [session] = useContext(sessionContext);
 
  useEffect(() =>
  {
    const fetchUser = async () => {
      try {
        setLoading(true); // Démarre le chargement
        const userDetail = await SearchUser(session.Token, params.state.userId);
        setUser({
                id : userDetail.data.user.id,
                lastName: userDetail.data.user.lastName,
                firstName: userDetail.data.user.firstName,
                email: userDetail.data.user.email,
              });
      } catch (error) {
        console.error("Erreur de recuperation de l'utilsateur", error);
      } finally {
        setLoading(false); // Termine le chargement
      }
    };
 
    fetchUser();
  }, [session.Token, params.state.userId]);
 
  return (
    <div
      className="d-flex justify-content-center align-items-start px-5 overflow-y-auto py-lg-4"
      style={{ height: "100vh" }}
    >
 
      <div
        className="ombre-style d-flex justify-content-center border border-2 border-danger mt-lg-0 mt-4"
        style={{ width: "600px", borderRadius: "25px" }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center w-100 px-4">
          <img
            src="./assets/images/logo.svg"
            alt="logo"
            width="20%"
            className="my-3 d-lg-block d-none"
          />
          <h4 className="fw-bold text-center mb-0 fs-10 mt-2 mt-lg-0">
            Détaille d'un utilisateur
          </h4>
          {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />}
          <p className="text-secondary text-center mx-4"></p>
          <Form>
              <Form.Group controlId="formPrenom" className="mb-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Label style={{ marginRight: '10px', fontWeight: 'bold' }}>Prénom</Form.Label>
                  <Form.Control
                    style={{ width: '300px' }}
                    type="text"
                    name="firstName"
                    disabled
                    value={user.firstName}
                  />
                </div>
              </Form.Group>        
              <Form.Group controlId="formNom" className="mb-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Label style={{ marginRight: '10px', fontWeight: 'bold' }}>Nom</Form.Label>
                  <Form.Control
                    style={{ marginLeft: '20px', width: '300px' }}
                    type="text"
                    name="lastName"
                    disabled
                    value={user.lastName}
                  />
                </div>
              </Form.Group>
            <Form.Group controlId="formEmail" className="mb-3">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Label style={{ marginRight: '10px', fontWeight: 'bold' }}>Email</Form.Label>
                <Form.Control
                  style={{ marginLeft: '20px', width: '300px' }}
                  type="email"
                  name="email"
                  disabled
                  value={user.email}
                />
              </div>
            </Form.Group>
 
            <div className="d-flex justify-content-center"> {/* Centered div */}
              <div className="mb-4"> {/* Adjust margin-bottom as needed */}
                <Link to="/UsersList">
                  <Button variant="primary">Retour</Button>
                </Link>
              </div>
            </div>
           
          </Form>
        </div>
      </div>
    </div>
  );
}