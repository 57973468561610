import "./Styles.css";
import React, { useContext, useState, useEffect } from "react";
import { Form, Button, Tooltip, OverlayTrigger, Alert } from "react-bootstrap";
import { Link, useLocation ,useNavigate } from "react-router-dom";
import { LuMailCheck } from "react-icons/lu";
import Spinners, { Alerts } from "../../Components/Commons/Common";
import { Spinner } from 'react-bootstrap';
import { SearchUser, UserUpdate } from "../../Backend/api";
import { sessionContext } from "../../App";
 
export default function UpdateUser()
{
  const params = useLocation();
  const [ErrorConnexion ] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({id: "",lastName: "",firstName: "",email: "",});
  const [session] = useContext(sessionContext);
  const navigate = useNavigate();
  const [Alerte, setAlerte] = useState({ State: false, Title: "", Type: "", Message: "", Duree: 15,});
  const userUpdate = UserUpdate();
 
  useEffect(() =>
  {
    const fetchUser = async () => {
      try {
        setLoading(true); // Démarre le chargement
        console.log(params.state);
        const userDetail = await SearchUser(session.Token, params.state.userId);
        setUser({
                id : userDetail.data.user.id,
                lastName: userDetail.data.user.lastName,
                firstName: userDetail.data.user.firstName,
                email: userDetail.data.user.email,
              });
      } catch (error) {
            console.error("Erreur de recuperation de l'utilsateur", error);
      } finally {
        setLoading(false); // Termine le chargement
      }
    };
 
    fetchUser();
  }, [session.Token, params.state.userId]);
 
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
 
  const handleUpdateUser = () => {
      userUpdate.mutate({id: user.id, lastName: user.lastName, firstName: user.firstName, email: user.email, token: session.Token});
  };

  if (userUpdate.isSuccess) {
        userUpdate.reset();
        setAlerte({
                State: true,
                Title: 'Succès',
                Type: 'success',
                Message: userUpdate.data.message,
                Duree: 5,
        });
  }

  if (userUpdate.isError) {
    userUpdate.reset();
    if (userUpdate.error.response && userUpdate.error.response.status === 403 && userUpdate.error.response.data && userUpdate.error.response.data.message) {
        // Erreur de validation des entrées, adresse e-mail déjà utilisée
        setAlerte({
          State: true,
          Title: 'Erreur de validation',
          Type: 'danger',
          Message: userUpdate.error.userUpdate.data.message,
          Duree: 15,
        });
      } else {
        console.log(userUpdate.error.response);
        // Erreur générique
        setAlerte({
          State: true,
          Title: 'Erreur',
          Type: 'danger',
          Message: "Une erreur s'est produite lors de la communication avec le serveur. Veuillez réessayer.",
          Duree: 15,
        });
      }
  }
 
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <ul className="text-start m-0 p-0">
        <li className="m-0">- La longueur minimale doit être de plus de 3 caractères</li>
        <li className="m-0">- Il doit contenir seulement des caractères</li>
      </ul>
    </Tooltip>
  );
 
  function onCloseAlert()
  {
    setAlerte({ State: false });
    if (Alerte.Type === 'success') {
        navigate('/UsersList');
    }
  }

  if (userUpdate.isLoading) {
    return (
        <Spinners Message={"Mise à jour en cours..., Veuillez patienter s'il-vous-plaît."} />
      );
  }
 
  return (
    <div
      className="d-flex justify-content-center align-items-start px-5 overflow-y-auto py-lg-4"
      style={{ height: "100vh" }}
    >
 
      <div
        className="ombre-style d-flex justify-content-center border border-2 border-danger mt-lg-0 mt-4"
        style={{ width: "600px", borderRadius: "25px" }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center w-100 px-4">
          <img
            src="./assets/images/logo.svg"
            alt="logo"
            width="20%"
            className="my-3 d-lg-block d-none"
          />
          <h4 className="fw-bold text-center mb-0 fs-10 mt-2 mt-lg-0">
            Modification d'un utilisateur
          </h4>
          {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />}
          <p className="text-secondary text-center mx-4"></p>
          <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px' }}>
          <Alert
            key="success"
            variant="success"
            className={
              !params.state.Logged
                ? "d-block py-0 px-1 text-center mb-1"
                : "d-none"
            }
            style={{ fontSize: "16px", fontWeight: "bold", textAlign: "center" }}
          >
            <LuMailCheck className="fs-5 me-2 text-success" />
            {params.state.message}
          </Alert>
          </div>
 
          <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px' }}>
          <Alert
            key="danger"
            variant="danger"
            className={ErrorConnexion !== "" ? "d-block py-2 mb-1 text-center" : "d-none"}
            style={{ fontSize: "16px", fontWeight: "bold", textAlign: "center" }}
          >
            {ErrorConnexion}
          </Alert>
          </div>
          <Form>
           <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
              <Form.Group controlId="formPrenom" className="mb-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Label style={{ marginRight: '10px', fontWeight: 'bold' }}>Prénom</Form.Label>
                  <Form.Control
                    style={{ width: '300px' }}
                    type="text"
                    placeholder="Entrez votre prénom"
                    name="firstName"
                    value={user.firstName}
                    onChange={handleInputChange}
                  />
                </div>
              </Form.Group>
            </OverlayTrigger>            
            <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
              <Form.Group controlId="formNom" className="mb-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Label style={{ marginRight: '10px', fontWeight: 'bold' }}>Nom</Form.Label>
                  <Form.Control
                    style={{ marginLeft: '20px', width: '300px' }}
                    type="text"
                    placeholder="Entrez votre nom de famille"
                    name="lastName"
                    value={user.lastName}
                    onChange={handleInputChange}
                  />
                </div>
              </Form.Group>
            </OverlayTrigger>
            <Form.Group controlId="formEmail" className="mb-3">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Label style={{ marginRight: '10px', fontWeight: 'bold' }}>Email</Form.Label>
                <Form.Control
                  style={{ marginLeft: '20px', width: '300px' }}
                  type="email"
                  placeholder="Entrez votre adresse courriel"
                  name="email"
                  value={user.email}
                  onChange={handleInputChange}
                />
              </div>
            </Form.Group>
 
            <div className="d-flex justify-content-center"> {/* Centered div */}
              <div className="mb-4"> {/* Adjust margin-bottom as needed */}
                <Button variant="success" onClick={handleUpdateUser} className="me-3">
                Mise à jour
                </Button>
                <Link to="/UsersList">
                  <Button variant="primary">Retour</Button>
                </Link>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Alerts
        State={Alerte.State}
        Type={Alerte.Type}
        Title={Alerte.Title}
        Message={Alerte.Message}
        Duree={Alerte.Duree}
        onClose={onCloseAlert}
      />
    </div>
  );
}
 