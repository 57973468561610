import { Navigate } from "react-router-dom";

export default function Anonymous({ Logged, children })
{
  if (Logged)
{
    return <Navigate to="/VirtualCard" replace />;
  }
  return children;
}
