import React from "react";
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <HashRouter>
      <QueryClientProvider client={queryClient}>
            <App />
      </QueryClientProvider>
  </HashRouter>
);
