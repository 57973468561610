import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, InputGroup, Alert } from "react-bootstrap";
import { BiSolidUser } from "react-icons/bi";
import { BsSendFill } from "react-icons/bs";
import { RequestForgotPassword } from "../../Backend/api";
import Spinners, { Alerts } from "../../Components/Commons/Common";

export default function ForgottenPassword()
{
  const forgotPassword = RequestForgotPassword();

  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [ErrorConnexion, setErrorConnexion] = useState("");
  const [Alerte, setAlerte] = useState({
    State: false,
    Title: "",
    Type: "",
    Message: "",
  });

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false)
{
      event.stopPropagation();
      setValidated(true);
    } else {
      forgotPassword.mutate({ email: event.target.Email.value });
    }
  };

  function onFermerAlert()
{
    setAlerte({ State: false });
  }

  if (forgotPassword.isError)
{
    if (forgotPassword.error.response.data)
{
      setErrorConnexion(
        "Code: " +
          forgotPassword.error.response.status +
          " - " +
          forgotPassword.error.response.data.message
      );
      setAlerte({
        State: true,
        Title:  "Erreur - Code: " + forgotPassword.error.response.status,
        Type: "ERROR",
        Message: forgotPassword.error.response.data.message,
      });
    }
    forgotPassword.reset();
  } else if (forgotPassword.isSuccess)
  {
    forgotPassword.reset();
    navigate("/ResetForgotPassword");
  }

  if (forgotPassword.isLoading)
{
    return (
      <Spinners
        Message={
          "Envoie du mail en cours..., Veuillez patienter s'il-vous-plaît."
        }
      />
    );
  }

  return (
    <>
      <div className="row d-flex justify-content-center mt-4">
        <div className="col-9 col-xs-8 col-sm-8 col-md-6 col-lg-6 col-xl-4">
          <div
            className="ombre-style d-flex justify-content-center border border-2 border-danger"
            style={{ borderRadius: "25px" }}
          >
            <div className="d-flex flex-column align-items-center justify-content-center w-75">
              <img
                src="./assets/images/logo.svg"
                alt="logo"
                width="50%"
                className="my-3 p-lg-3 p-0"
              />
              <h4 className="mt-1 fw-bold text-center">
                Entrez le courriel lié au compte
              </h4>
              <p className="text-secondary text-center mx-lg-4 mx-0 d-none">
                Entrez votre courriel d'identifiant en tant qu'utilisateur.
              </p>
              <Alert
                key="danger"
                variant="danger"
                className={
                  ErrorConnexion !== "" ? "d-block py-0 mx-2" : "d-none"
                }
                style={{ fontSize: "12px" }}
              >
                {ErrorConnexion}
              </Alert>
              <Form
                id="form-connexion"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                className="w-100"
              >
                <Form.Group controlId="Email" className="mb-3 px-0">
                  <InputGroup hasValidation>
                    <InputGroup.Text
                      id="inputGroupPrepend"
                      className="bg-danger border border-1 border-danger"
                    >
                      <BiSolidUser className="fs-3 my-0 text-light" />
                    </InputGroup.Text>
                    <Form.Control
                      className="border border-1 border-danger"
                      type="email"
                      placeholder="Courriel"
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="mt-0"
                      style={{ fontSize: "12px" }}
                    >
                      Le l'adresse mail est requis.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <div className="d-flex flex-column justify-content-center my-4">
                  <button
                    type="submit"
                    className="btn btn-danger mx-5 d-flex align-items-center justify-content-center"
                  >
                    <BsSendFill className="fs-2 me-2" />
                    Envoyer
                  </button>
                </div>
                <Link
                  to="/"
                  className="d-flex justify-content-center w-100 mb-4"
                  style={{ textUnderlineOffset: "5px" }}
                >
                  Retour à la page de connexion
                </Link>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Alerts
        State={Alerte.State}
        Type={Alerte.Type}
        Title={Alerte.Title}
        Message={Alerte.Message}
        onFermer={onFermerAlert}
      />
    </>
  );
}
