import { Button, Form } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegPenToSquare } from "react-icons/fa6";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IoMdAddCircleOutline } from "react-icons/io";
import { BsFillBuildingsFill } from "react-icons/bs";
import { IoSearchOutline } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { React, useState, useRef, useEffect, useContext } from "react";
import { GoogleMap, MarkerF, MarkerClustererF, useJsApiLoader, Marker } from "@react-google-maps/api";
import Spinners, { Height100, GetScreenSize } from "../../Components/Commons/Common";
import { useNavigate } from "react-router-dom";
import { CompaniesLists, DeleteCompany } from '../../Backend/api';
import { sessionContext} from "../../App";
import { useQuery } from "react-query";
import { setDefaults, fromAddress,} from "react-geocode";
import ModalConfirmation from "../../Components/Commons/ModalDlg";


export function ButtonAction({id, onDelete, onUpdate, onView}) {
    return (
        <div className='d-flex'>
            <Button variant='danger' className="mx-2 my-1 my-lg-0 d-flex align-items-center justify-content-center" 
                data-toggle="tooltip" data-placement="left" title="Permet de supprimer un entreprise"
                onClick={() => onDelete(id)} 
            >
                <RiDeleteBin6Line className="fs-4 me-0 me-lg-2"/> <span className="d-none d-lg-inline">Supprimer</span>
            </Button>
            <Button variant='success' className="mx-2 my-1 my-lg-0 d-flex align-items-center justify-content-center" 
                data-toggle="tooltip" data-placement="left" title="Permet de modifier un entreprise"
                onClick={() => onUpdate(id)} 
            >
                <FaRegPenToSquare className="fs-4 me-0 me-lg-2"/> <span className="d-none d-lg-inline">Modifier</span>
            </Button>
            <Button variant='secondary' className="mx-2 my-1 my-lg-0 d-flex align-items-center justify-content-center" 
                data-toggle="tooltip" data-placement="left" title="Permet de visualiser un entreprise"
                onClick={() => onView(id)} 
            >
                <MdOutlineRemoveRedEye className="fs-4 me-0 me-lg-2"/> <span className="d-none d-lg-inline">Détails</span>
            </Button>
        </div>
    )
}


export default function CompaniesList() {
    let rList = useRef();
    const [refList, setRefList] = useState();
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]);
    const [session] = useContext(sessionContext);
    const [listCompanies, setListCompanies] = useState([{id:0, lat: 0, lng: 0}]);
    const [loading, setLoading] = useState(false);
    const [modalConfirm, setModalConfirm] = useState({
        Action: -1,
        State: false,
        Title: "",
        Message: "",
        TxtBtnConfirm: "",
        TxtBtnCancel: "",
        Type: "",
        companyId: 0
      });
    const deleteCompany = DeleteCompany();
    
    rList= Height100(rList);


    useEffect(()=>{
        const fetchUser = async () => {
            setLoading(true);
            const response =  await CompaniesLists(session.Token);
            console.log(response);
            setCompanies(response.data.data);        
            setLoading(false);

            setDefaults({
                key: "AIzaSyB7cDunG0T0fH0M68v_BetgeGhdHIpget4", // Your API key here.
                language: "fr", // Default language for responses.
                region: "ca", // Default region for responses.
            });

            let l = response.data.data;
            if (l) {
                l.map(async (item) => {
                    let slc = [];
                    const response = await fromAddress(item.address + ', ' +item.postal_code + ' Saint jean sur Richelieu, Canada');
                    if (response.results) {
                        if (response.results.length) {
                            const { lat, lng } = response.results[0].geometry.location;
                            let exist = false;
                            exist = l.map(i => {
                                if (i.id === item.id) {
                                    return true;
                                }
                            })
                            if (exist) {
                                slc.push({id: item.id, lat: lat, lng: lng});                        
                                setListCompanies(slc);
                            }
                        }
                    }
                });
            }
        }
        fetchUser();
        setRefList(rList.current);    
    }, [rList, setRefList, setLoading, setCompanies, session.Token]);

    function handleAdd()
    {
        navigate('/AddCompany');
    }

    function HandleModalConfirm(id)
    {
        setModalConfirm({State: false});
        deleteCompany.mutate({id: id, token: session.Token});
    }

    function HandleModalCancel()
    {
        setModalConfirm({State: false});
    }

    function handleDelete(id)
    {
        setModalConfirm({State: true, Type: "ERROR", Title:  "SUPPRESSION ENTREPRISE",
            Message: "Voulez-vous vraiment supprimer cet entreprise ?", TxtBtnConfirm: "Suprrimer", TxtBtnCancel: "Annuler", companyId: id});
    }

    if (deleteCompany.isSuccess) 
    {
        deleteCompany.reset();
        navigate("/Confirmation", {
            state: {
                message: "L'entreprise a été créer avec succès !",
                description: "",
                Logged: session.Logged,
                linkReturn: "/CompaniesList",
                textReturn: "Retour à la page liste entreprise"
            },
          });
    }

    if (deleteCompany.isError) 
    {
        deleteCompany.reset();
    }

    function handleUpdate(id)
    {
        navigate('/EditCompany', {
                state: {
                    id: id
                },
            });
    }

    function handleView(id)
    {
        alert(id);
    }

    function handleUsers(id, name)
    {
        navigate('/UsersList', {
            state: {
                id: id,
                name: name
            },
        });
    }

    if (loading)
    {
        return (
            <Spinners Message={"Chargement des données d'utilisateurs en cours..., Veuillez patienter s'il vous plait." } />
        );
    }

    if (deleteCompany.isLoading)
    {
        return (
            <Spinners Message={"Suppression de l'entreprise en cours..., Veuillez patienter s'il vous plait."} />
        );
    }

    return (
        <>
            <div className='row'>
                <div className='col'>
                    <div className='py-3 border-bottom border-danger text-danger d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center bg-danger'>
                        <h2 className='fw-bold mx-2 d-flex align-items-end mb-3 mb-md-0 text-light'>
                            <BsFillBuildingsFill className="fs-3 me-2" />
                            LES ENTREPRISES
                        </h2>
                        <div className='d-flex'>
                            <Button variant="info" className="me-3 ms-2 text-primary d-flex justify-content-center align-items-center" 
                                onClick={() => handleAdd()}  data-toggle="tooltip" data-placement="left" title="Ajouter une nouvelle employer">
                                    <IoMdAddCircleOutline className="fs-4 me-0 me-lg-2"/> <span className="d-none d-lg-inline">Ajouter</span>
                            </Button>
                            <div className="d-flex me-2 justify-content-between">
                                <Form.Control type="text" placeholder="Trouver votre entreprise" className="border-primary" style={{borderTopRightRadius: '0', borderBottomRightRadius: '0'}}/>
                                <Button style={{borderTopLeftRadius: '0', borderBottomLeftRadius: '0'}}><IoSearchOutline className="fs-5"/></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row">
                <div className="col-12 col-lg-7 me-0 pe-0">
                    <div ref={rList} className="overflow-x-hidden overflow-y-scroll">
                        <div className={companies.length === 0 ?'d-flex justify-content-center w-100':'d-none'}>Aucun entreprise n’a été trouvé</div>
                        
                        {companies?companies.map(item => {
                            return(
                                <div className="row d-flex justify-content-center justify-content-xl-start">
                                    <div className="col-11 col-xl-12">
                                        <div className="d-flex flex-column flex-md-row border-bottom border-danger px-2 py-lg-2 py-2">
                                            
                                            <div className="d-flex flex-column justify-content-between align-items-md-start align-items-center ms-0 ms-md-4 w-100">
                                                <div className="d-flex flex-column justify-content-center h-100">
                                                    <div className="text-center text-md-start" style={{fontFamily: 'Menlo,monaco,Consolas,Lucida Console,monospace'}}></div>
                                                    <div className="text-center text-md-start">SERVICES PROFESSIONNELS</div>
                                                    <h2 className="text-danger text-center text-md-start" style={{fontFamily: 'Sriracha, sans-serif'}}>{item.name}</h2>
                                                </div>
                                                <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between w-100 align-items-center">
                                                    <Button variant='warning' className="my-1 my-lg-0 d-flex align-items-center justify-content-center text-secondary" onClick={() => handleUsers(item.id, item.name)}
                                                        data-toggle="tooltip" data-placement="left" title="Voir les employers"><FaUsers className='me-2 fs-5'/>Total ({item.count_user})
                                                    </Button>
                                                    <ButtonAction id= {item.id} onDelete= {handleDelete} onUpdate= {handleUpdate} onView= {handleView} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }):''}
                    </div>
                </div>

                <div className="col-5 d-none d-lg-block ps-0" >
                    <Map topElement={refList ? refList.offsetTop : 0} listCompanies={listCompanies}/>
                </div>
            </div>

            <ModalConfirmation
        State={modalConfirm.State}
        Title={modalConfirm.Title}
        Message={modalConfirm.Message}
        ConfirmTextBtn={modalConfirm.TxtBtnConfirm}
        CancelTextBtn={modalConfirm.TxtBtnCancel}
        Type={modalConfirm.Type}
        onConfirm={HandleModalConfirm}
        onCancel={HandleModalCancel}
        data={modalConfirm.companyId}
      />
        </>
    )
}


function Map({topElement, listCompanies}) {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyB7cDunG0T0fH0M68v_BetgeGhdHIpget4',
    });

    const [center, setCenter] = useState()

    const options = {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
    };

    useEffect(()=>{
        setCenter({ 
            lat: listCompanies.length?listCompanies[0].lat:0, 
            lng: listCompanies?listCompanies[0].lng:0, 
        });
    },[listCompanies]);

    const screenSize = useRef();
    screenSize.current= GetScreenSize();

    const containerStyle = {
        width: '100%',
        height: (screenSize.current.height - topElement) + 'px',
    };

    function handleMarkerClick (id) {
        alert(id);
    }
  
    if (!isLoaded) return <div>Loading...</div>;
  
    return (
        <GoogleMap zoom={15} options={options} center={center} mapContainerStyle={containerStyle} >
            {listCompanies?listCompanies.map((marker) => (<Marker key={marker.id} position={{lat: marker.lat, lng: marker.lng}} onClick={()=>handleMarkerClick(marker.id)} />)):''}
        </GoogleMap>
    );
};
