import React, { useContext, useRef } from "react";
import { sessionContext, userContext} from "../App";
import { DestroySession } from "../Components/Security/Session";
import Spinners from "../Components/Commons/Common";
import { useQuery } from "react-query";
import { Profil } from "../Backend/api";
import QRCode from "react-qr-code";

export default function VirtualCard()
{
  const [session, setSession] = useContext(sessionContext);
  const [user, setUser] = useContext(userContext);
  const ExecuteCount = useRef(0);

  const profile = useQuery(["utilisateur", session.Token], () =>
    Profil(session.Token)
  );

  if (profile.isSuccess)
  {
    if (ExecuteCount.current < 2)
    {
      ++ExecuteCount.current;
      setUser({
        Id: profile.data.data.data.user.id,
        Lastname: profile.data.data.data.user.lastName,
        Firstname: profile.data.data.data.user.firstName,
        Email: profile.data.data.data.user.email,
      });
    }
  }

  if (profile.isError)
  {
    if (ExecuteCount.current < 2)
    {
      ++ExecuteCount.current;
      DestroySession();
      setSession({ Logged: false, Token: "", Id: "" });
    }
  }

  if (profile.isLoading)
  {
    return (
      <Spinners Message={ "Chargement des données d'utilisateurs en cours..., Veuillez patienter s'il vous plait." } />
    );
  }

  return (
    <>
      <div className="row d-flex justify-content-center mt-4">
        <div className="col-10 col-xs-8 col-sm-6 col-md-5 col-lg-4 col-xl-3 row d-flex justify-content-center">
          <div
            className="ombre-style border border-2 border-danger d-flex align-items-center flex-column pb-4 p-0"
            style={{ width: "250px", borderRadius: "25px" }}
          >
            <div
              className="qrcode d-flex flex-column align-items-center justify-content-center w-100"
              style={{ position: "relative" }}
            >
              <div
                className="bg-danger w-100"
                style={{
                  zIndex: "-10000",
                  top: "0",
                  position: "absolute",
                  height: "225px",
                  borderTopLeftRadius: "23px",
                  borderTopRightRadius: "23px",
                }}
              ></div>
              <div
                style={{ zIndex: "1000" }}
                className="d-flex flex-column align-items-center"
              >
                <img
                  src="./assets/images/logo_primary.svg"
                  alt=""
                  style={{ width: "100px", height: "auto", zIndex: "10000" }}
                  className="mt-3 mb-4"
                />
                <div
                  style={{
                    height: "auto",
                    margin: "0 auto",
                    maxWidth: 150,
                    width: "100%",
                    zIndex: "1000",
                  }}
                >
                  <QRCode
                    fgColor="#35435F"
                    size={500}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={user.Email}
                    viewBox={`0 0 500 500`}
                  />
                </div>
                <div className="d-flex flex-column align-items-center my-3">
                  <span className="fw-bold mb-0 fs-5 text-danger">
                    {" "}
                    {user.Firstname}{" "}
                  </span>
                  <span className="fw-bold mt-0 fs-5 text-danger">
                    {" "}
                    {user.Lastname}{" "}
                  </span>
                </div>
              </div>
            </div>
            <img
              src="https://webeex.com/wp-content/uploads/2020/11/logo-webeex-informatique-1ligne.png"
              alt=""
              style={{ width: "200px", height: "auto", zIndex: "1000" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
