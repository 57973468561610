import { Container, Navbar, Offcanvas, Form, Button } from "react-bootstrap";
import "./Styles.css";
import React, { useContext, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { userContext, sessionContext} from "../../App";
import {
  BiSolidUserCircle,
  BiLogOutCircle,
  BiSolidUserRectangle,
} from "react-icons/bi";
import { AiOutlineMenu } from "react-icons/ai";
import ModalConfirmation from "./ModalDlg";
import { Logout } from "../../Backend/api";
import { DestroySession } from "../Security/Session";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import useBreapoint from "./Breakpoints";
import { HiUsers } from "react-icons/hi";
import {
  BsFillBuildingsFill,
  BsFillPersonVcardFill,
  BsSearch,
} from "react-icons/bs";
import { CgPassword } from "react-icons/cg";
import Spinners from "./Common";

export default function Header()
{
  const [session, setSession] = useContext(sessionContext);
  const [user] = useContext(userContext);
  const [modalConfirm, setModalConfirm] = useState({
    Action: -1,
    State: false,
    Title: "",
    Message: "",
    TxtBtnConfirm: "",
    TxtBtnCancel: "",
    Type: "",
  });
  const breakpoint = useBreapoint();
  const navigate = useNavigate();

  function StringToColor(string)
  {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1)
    {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1)
    {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function StringAvatar(name)
  {
    let spaceCount = name.split(" ").length;
    let result = "";
    for (let i = 0; i < spaceCount; ++i)
    {
      result = result + name.split(" ")[i][0];
    }
    return result;
  }

  const logout = Logout();
  if (logout.isError)
  {
    logout.reset();
  } else if (logout.isSuccess)
  {
    logout.reset();
    DestroySession();
    setSession({ Logged: false, Token: "", Id: "" });
    navigate("/");
  }

  //------------------------------Logout------------------------------
  function HandleLogout()
{
    setModalConfirm({
      State: true,
      Type: "ERROR",
      Title:  "Déconnexion de l'utilisateur",
      Message: "Voulez-vous vraiment vous déconnecter ?",
      TxtBtnConfirm: "Déconnecter",
      TxtBtnCancel: "Annuler",
    });
  }

  //----------------------------EVENT MODAL--------------------------
  function HandleModalConfirm()
  {
    setShowMenu(false);
    setShowUser(false);
    setModalConfirm({ State: false });
    logout.mutate(session.Token);
    navigate('/');
  }

  function HandleModalCancel()
  {
    setModalConfirm({ State: false });
  }

  const [showMenu, setShowMenu] = useState(false);
  const [showUser, setShowUser] = useState(false);

  const handleCloseMenu = () => setShowMenu(false);
  const handleShowMenu = () => setShowMenu(true);

  const handleCloseUser = () => setShowUser(false);
  const handleShowUser = () => setShowUser(true);

  const handleUsers = () => {
    navigate("/UsersList");
    setShowMenu(false);
    setShowUser(false);
  };

  const handleCompanies = () => {
    navigate("/CompaniesList");
    setShowMenu(false);
    setShowUser(false);
  }

  
  if (logout.isLoading)
  {
      return (
          <Spinners Message={"Déconnexion en cours..., Veuillez patienter s'il-vous-plait."}/>
      );
  }

  return (
    <>
      <Navbar expand="lg" className="Navbar bg-primary">
        <Container fluid className={
            session.Logged
              ? "d-flex flex-row justify-content-between"
              : "d-flex flex-row justify-content-center"
          }
        >
          <div className={user.Lastname !== "" ? session.Logged ? breakpoint === "lg" || breakpoint === "xl" || breakpoint === "xxl" ? "d-line" : "d-line" : "d-none" : "d-none"}>
            <button onClick={() => handleShowMenu()} className="btn btn-danger">
              <AiOutlineMenu className="fs-3" />
            </button>
            <Offcanvas
              show={showMenu}
              onHide={handleCloseMenu}
              placement="start"
              className="bg-primary text-light"
            >
              <Offcanvas.Header
                className="d-flex justify-content-end"
                closeButton
              ></Offcanvas.Header>
              <Offcanvas.Body>
                <div className="my-4 d-flex flex-column w-100 justify-content-center border border-0 border-danger rounded-4">
                  <Button className="btn btn-secondary my-2 text-start" onClick={() => handleCompanies()}>
                    <BsFillBuildingsFill className="fs-3 me-2" />
                    ENTREPRISES
                  </Button>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>

          <Link to="/" className="fw-bold text-light d-flex justify-content-center align-items-center text-decoration-none" style={{cursor: 'pointer'}}>
            <span
              className={
                breakpoint === "lg" ||
                breakpoint === "xl" ||
                breakpoint === "xxl"
                  ? "d-line"
                  : "d-none"
              }
            >
              SDVSJ
            </span>
            <img
              src="./assets/images/logo.svg"
              alt="Logo Vieux-Saint-Jean"
              width="50"
              height="50"
            />
          </Link>

          <div
            className={
              user.Lastname !== ""
                ? session.Logged
                  ? breakpoint === "lg" ||
                    breakpoint === "xl" ||
                    breakpoint === "xxl"
                    ? "d-line"
                    : "d-line"
                  : "d-none"
                : "d-none"
            }
          >
            <OverlayTrigger
              placement={"left"}
              show={false}
              overlay={
                <Tooltip
                  className="custom-tooltip d-flex align-items-center justify-content-center"
                  id={`tooltip-${"left"}`}
                >
                  <BiSolidUserCircle className="fs-2 me-2" />
                  <span className="text-nowrap">
                    { user.Firstname} <br></br> {user.Lastname}
                  </span>
                </Tooltip>
              }
            >
              <Button
                onClick={() => handleShowUser()}
                className="btn btn-danger text-light rounded-circle fw-bold d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: StringToColor( user.Firstname + " " + user.Lastname),
                  width: "50px",
                  height: "50px",
                  cursor: "pointer",
                }}
              >
                {StringAvatar( user.Firstname + " " + user.Lastname)}
              </Button>
            </OverlayTrigger>

            <Offcanvas
              show={showUser}
              onHide={handleCloseUser}
              placement="end"
              className="bg-primary text-light"
            >
              <Offcanvas.Header closeButton className="pb-0"></Offcanvas.Header>
              <Offcanvas.Body className="text-light d-flex flex-column align-items-center pt-0">
                <BiSolidUserCircle
                  className="text-danger"
                  style={{ fontSize: "50px" }}
                />
                <h5 className="w-100 text-center mt-3 mb-2">Bonjour,</h5>
                <h4 className="fw-bold text-center">
                   {user.Firstname} {user.Lastname}
                </h4>
                <div className="my-4 d-flex flex-column w-100 justify-content-center align-items-center border border-1 border-danger p-3 rounded-4">
                  <button disabled className="btn btn-secondary my-2 w-100">
                    <BiSolidUserRectangle className="fs-3 me-2" />
                    Mon profil
                  </button>
                  <button
                    className="btn btn-secondary my-2 w-100"
                    onClick={() => {
                      navigate("/VirtualCard");
                      setShowUser(false);
                    }}
                  >
                    <BsFillPersonVcardFill className="fs-3 me-2" />
                    Ma carte
                  </button>
                  <button
                    onClick={() => {
                      navigate("/ChangePassword", {
                        state: {
                          message: "",
                          Logged: session.Logged,
                          Email: session.Id,
                        },
                      });
                      setShowUser(false);
                    }}
                    className="btn btn-secondary my-2 w-100"
                  >
                    <CgPassword className="fs-3 me-2" />
                    Changer mot de passe
                  </button>
                  <div className="border border-1 border-secondary w-100 m-2"></div>
                  <button
                    onClick={() => HandleLogout()}
                    className="btn btn-danger my-2 w-100"
                  >
                    <BiLogOutCircle className="fs-3 me-2" />
                    Déconnecter
                  </button>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </Container>
      </Navbar>
      <Outlet />

      <ModalConfirmation
        State={modalConfirm.State}
        Title={modalConfirm.Title}
        Message={modalConfirm.Message}
        ConfirmTextBtn={modalConfirm.TxtBtnConfirm}
        CancelTextBtn={modalConfirm.TxtBtnCancel}
        Type={modalConfirm.Type}
        onConfirm={HandleModalConfirm}
        onCancel={HandleModalCancel}
      />
    </>
  );
}
