import React, { useEffect, useRef, useState } from "react";
import { Toast, Spinner } from "react-bootstrap";

export function getError(dataBackend, param) {
  let p = dataBackend.error.response.data.data[param];
  let m = '';
  if (p) {
      for (let i = 0; i < p.length; ++i) {
          m = m + p[i] + "\n";
      }
  }
  return m;
}

export function GetScreenSize() {

  function getCurrentDimension() {
      return {
            width: window.innerWidth,
            height: window.innerHeight
      }
  }

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
      const updateDimension = () => {
          setScreenSize(getCurrentDimension());
      }
      window.addEventListener('resize', updateDimension);
      
      return(() => {
          window.removeEventListener('resize', updateDimension);
      });        
  }, [screenSize]);

  return screenSize;
}

export function Height100(refElement, offset = 0) {
    const screenSize = useRef();
    const refContainer = useRef(refElement);

    screenSize.current = GetScreenSize();

    useEffect(() => {
        if (refContainer.current && typeof refContainer.current.style !== 'undefined') {
            if (screenSize.current.height < refContainer.current.offsetHeight + refContainer.current.offsetTop) {
              refContainer.current.style.height= ((refContainer.current.offsetHeight - (refContainer.current.offsetHeight + refContainer.current.offsetTop - screenSize.current.height) - 3) - offset) + 'px';
            } else {  
                refContainer.current.style.height= ((screenSize.current.height - refContainer.current.offsetTop - 3) - offset) + 'px';
            }
        }
    }, [screenSize]);

    return refContainer;
}

export default function Spinners({ Message })
{
  return (
    <div
      className="w-100 d-flex flex-column justify-content-center align-items-center px-5"
      style={{ height: "100vh" }}
    >
      <div className="d-flex mb-3">
        <div
          className="px-2 py-1 bg-primary"
          style={{ border: "1px solid black", borderRight: "none" }}
        >
          <img src="./assets/images/logo.svg" width="50px" alt="" />
        </div>
        <div className="px-4 py-1 bg-danger border border-danger d-flex align-items-center">
          <h1 className="fw-bold my-0 text-light">SDVSJ</h1>
        </div>
      </div>

      <Spinner animation="grow" variant="danger" />

      <h5 className="text-center">{Message}</h5>
    </div>
  );
}

export function Alerts({ State, Type, Title, Message, onClose, Duree = 10 })
{
  const initCounter = Duree * 1000;
  const [counter, setCounter] = useState(initCounter / 1000);
  const [bgAlert, setBgAlert] = useState("bg-info");
  const [textAlert, setTextAlert] = useState("text-info");
  const [iconAlert, setIconAlert] = useState("");

  useEffect(() => {
    let timer;

    switch (Type)
{
      case "SUCCESS":
        setBgAlert("bg-success");
        setTextAlert("text-success");
        setIconAlert("bi bi-check-circle fs-3 text-light");
        break;
      case "ERROR":
        setBgAlert("bg-danger");
        setTextAlert("text-danger");
        setIconAlert("bi bi-exclamation-circle fs-3 text-light");
        break;
      case "WARNING":
        setBgAlert("bg-warning");
        setTextAlert("text-warning");
        setIconAlert("bi bi-exclamation-triangle fs-3 text-light");
        break;
      case "INFO":
        setBgAlert("bg-info");
        setTextAlert("text-info");
        setIconAlert("bi bi-info-circle fs-3 text-light");
        break;
      default:
        break;
    }

    if (State)
    {
        timer = setInterval(() => {
            let a = counter;
            --a;
            setCounter(a);
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }

    return () => {
        setCounter(initCounter / 1000);
    };
  }, [Type, State, counter, initCounter]);

  return (
    <Toast onClose={onClose} show={State} delay={initCounter} autohide className="mb-5 mx-2 position-absolute bottom-0 end-0 z-3" >
      <Toast.Header className={bgAlert}>
          <i className={iconAlert}></i>
          <strong className="me-auto px-3 text-light">{Title}</strong>
          <small className="text-light">{counter} Seconde(s)</small>
      </Toast.Header>
      <Toast.Body className={textAlert}>
          <pre>{Message}</pre>
      </Toast.Body>
    </Toast>
  );
}
