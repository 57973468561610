import React, { useEffect, useState } from "react";
import moment from "moment";
import ModalConfirmation from "../Commons/ModalDlg";

export function DetectTimeOut(props)
{
  let timer = undefined;
  const [stateAlert, setStateAlert] = useState(false);

  const events = ["click", "scroll", "load", "keydown", "mousemove"];

  useEffect(() => {
    addEvents(); // ajout des evenements au demarrage de l'application
    return () => {
      removeEvents(); // suppression des evenements au demarrage de l'application
      clearTimeout(timer);
    };
  });

  function eventHandler(eventType)
{
    if (props.isLogout)
{
      localStorage.setItem("lastInteractionTime", moment());
      if (timer)
{
        props.onActive();
        startTimer();
      }
    }
  }

  function addEvents()
{
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });
    startTimer();
  }

  function removeEvents()
{
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  }

  const startTimer = () => {
    if (timer)
{
      clearTimeout(timer);
    }

    timer = setTimeout(
      () => {
        let lastInteractionTime = localStorage.getItem("lastInteractionTime");
        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime))
        );
        let timeOutInterval = props.timeOutInterval
          ? props.timeOutInterval
          : props.Duration * 1000;
        if (!props.isLogout)
{
          clearTimeout(timer);
        } else {
          if (diff._milliseconds < timeOutInterval)
{
            startTimer();
            props.onActive();
          } else {
            props.onIdle();
            setStateAlert(true);
          }
        }
      },
      props.timeOutInterval ? props.timeOutInterval : props.Duration * 1000
    );
  };

  const handleContinueSession = () => {
    setStateAlert(false);
    //setLogout(false);
  };

  const HandleLogout = () => {
    removeEvents();
    clearTimeout(timer);
    //setLogout(true)
    props.onLogOut();
    setStateAlert(false);
  };

  return (
    <div>
      <ModalConfirmation
        Title="DÉCONNEXION EN COURS..."
        Message="Vous allez etre déconnecter automatiquement aprés une inactivité."
         ConfirmTextBtn="DéConnecter"
        CancelTextBtn="Continuer"
        State={stateAlert}
        Type="WARNING"
        AutoFermer="true"
        onConfirm={HandleLogout}
        onCancel={handleContinueSession}
      />
    </div>
  );
}

export function setSessionToken(token)
{
  localStorage.setItem("Token", token);
}

export function setSessionLogged(logged)
{
  localStorage.setItem("Logged", logged);
}

export function getSessionLogged()
{
  return localStorage.getItem("Logged");
}

export function getSessionToken()
{
  return localStorage.getItem("Token");
}

export function setSessionID(UserId)
{
  localStorage.setItem("Id", UserId);
}

export function getSessionID()
{
  return localStorage.getItem("Id");
}

export function DestroySession()
{
  localStorage.removeItem("Token");
  localStorage.removeItem("Id");
  localStorage.removeItem("Logged");
  localStorage.removeItem("lastInteractionTime");
}
