import GoogleMapReact from 'google-map-react';
import { Button } from 'react-bootstrap';

import { FaPhone, FaSearch } from 'react-icons/fa';
import { FaMapMarkerAlt } from 'react-icons/fa';
import CompanyLogo from './images/webeex_logo.jpg';
import logo from './images/logo_sdvsj.svg';

export default function CompanyDetails() {
  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const defaultProps = {
    center: {
      lat: 45.3223,
      lng: -73.1232,
    },
    zoom: 11,
  };
  return (
    <div className='FatherContainer'>
      <div className='blueBarre'>
        <a href='https://www.google.com'>
          <span className='d-line'>SDVSJ</span>
          <img src={logo} alt='Logo Vieux-Saint-Jean' width='50' height='50' />
        </a>
      </div>{' '}
      <div className='Container'>
        <div>
          <img src={logo} alt='Logo Vieux-Saint-Jean' className='details' />
        </div>
        <div className='navContainer'>
          {' '}
          <ul>
            <li>ACCUEIL</li>
            <li>COMMERCES</li>
            <li>ÉVÉNEMENTS</li>
            <li>BLOGUE</li>
            <li>ACTUALITÉS</li>
            <li>
              <FaSearch />
            </li>
          </ul>
        </div>
      </div>
      <div className='information'>
        <div>
          <img src={CompanyLogo} alt='Company Logo' className='company_logo' />
        </div>
        <div className='company_contact'>
          <div>
            <FaMapMarkerAlt color='white' size={20} />
            <p>296 2e Avenue Saint-Jean-sur-Richelieu J2X 2B6</p>
          </div>
          <div>
            <FaPhone color='white' size={20} />
            <p>450 347-7202</p>
          </div>
          <div>
            <Button variant='outline-light' href='/votre-chemin'>
              Site Web
            </Button>
          </div>
        </div>
      </div>
      <div className='description'>
        <div>
          <div>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </div>
        </div>
        <div style={{ height: '400px', width: '400px' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyAvaUO11InGAAUvK4IvdW-CRPj0UZnm4kw',
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent lat={45.3223} lng={-73.1232} text='My Marker' />
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
}
