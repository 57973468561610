import { Navigate } from "react-router-dom";

export default function Protected({ Logged, children })
{
  if (!Logged)
{
    return <Navigate to="/" replace />;
  }
  return children;
}
