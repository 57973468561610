import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";

export default function ModalConfirmation({
  Title,
  Message,
  ConfirmTextBtn,
  CancelTextBtn = "Cancel",
  State,
  Type,
  AutoClose = false,
  onConfirm,
  onCancel,
  data = null
})
{
  const initCounter = 10000;
  const [counter, setCounter] = useState(initCounter / 1000);
  const [bgAlert, setBgAlert] = useState("bg-info");
  const [textAlert, setTextAlert] = useState("text-info");
  const [iconAlert, setIconAlert] = useState("");

  useEffect(() => {
    if (State && AutoClose)
{
      const Timer = setInterval(() => {
        let a = counter;
        --a;
        setCounter(a);
        if (a === 0)
        {
          onConfirm();
        }
      }, 1000);
      return () => {
        clearInterval(Timer);
      };
    }

    switch (Type)
{
      case "SUCCESS":
        setBgAlert("bg-success text-light");
        setTextAlert("text-success");
        setIconAlert("bi bi-check-circle fs-3 text-light");
        break;
      case "ERROR":
        setBgAlert("bg-danger text-light");
        setTextAlert("text-danger");
        setIconAlert("bi bi-exclamation-circle fs-3 text-light");
        break;
      case "WARNING":
        setBgAlert("bg-warning text-light");
        setTextAlert("text-warning");
        setIconAlert("bi bi-exclamation-triangle fs-3 text-light");
        break;
      case "INFO":
        setBgAlert("bg-info text-light");
        setTextAlert("text-info");
        setIconAlert("bi bi-info-circle fs-3 text-light");
        break;
      default:
        break;
    }

    return () => setCounter(initCounter / 1000);
  }, [Type, State, counter, AutoClose, onConfirm]);

  return (
    <>
      <Modal show={State} onHide={onCancel} backdrop="static" keyboard={true}>
        <Modal.Header className={bgAlert} closeButton>
          <Modal.Title>
            <h4>
              <i className={iconAlert}></i>
              <span className="mx-3">{Title}</span>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={textAlert}>{Message}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              onCancel(data);
            }}
          >
            {CancelTextBtn}
          </Button>
          <Button
            className={bgAlert}
            onClick={() => {
              onConfirm(data);
            }}
          >
            {AutoClose ? "(" + counter + "s)" : ""} { ConfirmTextBtn}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
