import "./Styles.css";
import React, { useState, useContext, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Link, useLocation , useNavigate } from "react-router-dom";
import { LuMailCheck } from "react-icons/lu";
import Spinners, { Alerts } from "../../Components/Commons/Common";
import { RegisterUser } from "../../Backend/api";
import { sessionContext } from "../../App";


export default function CreateUser() 
{
  const [session] = useContext(sessionContext);
  const params = useLocation();
  const [ErrorConnexion] = useState("");
  const [user, setUser] = useState({lastName: "", firstName: "", email: ""});
  const [Alerte, setAlerte] = useState({State: false, Title: "", Type: "", Message: ""});
  const navigate = useNavigate();

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setUser({ ...user, [name]: value });
  };

  const registerUser = RegisterUser();

  if (registerUser.isError) {
      registerUser.reset();
      // Erreur générique
      setAlerte({State: true, Title: 'Erreur', Type: 'danger',
          Message: "Une erreur s'est produite lors de la communication avec le serveur. Veuillez réessayer.",
          Duree: 15,
      });
}

if (registerUser.isSuccess) {
    registerUser.reset();
    /*navigate("/Confirmation", {
      state: {
          message: "L'utilisateur a été créer avec succès !",
          description: "Un courriel contenant le mot de passe temporaire de l'administrateur a été envoyé",
          Logged: session.Logged,
          linkReturn: "/UsersList",
          textReturn: "Retour à la page liste utilisateur"
      },
    });*/
    navigate("/UsersList", {
      state: {
        id: params.state.company_id,
        name: params.state.company_name
      }
    })
}

function handleReturn(){
  navigate("/UsersList", {
    state: {
      id: params.state.company_id,
      name: params.state.company_name
    }
  })
}

  const handleCreateUser = async () => {
      registerUser.mutate({company_id: params.state.company_id, email: user.email, lastName: user.lastName, firstName: user.firstName, token: session.Token});
  };
  
  function onCloseAlert() {
      setAlerte({ State: false });
      navigate('/UsersList');
  }

  if (registerUser.isLoading) {
      return (
          <Spinners Message={"Enregistrement d'utilisateur en cours..., Veuillez patienter s'il-vous-plaît."} />
      );
  } 

  return (
    <div
      className="d-flex justify-content-center align-items-start px-5 overflow-y-auto py-lg-4"
      style={{ height: "100vh" }}
    >

      <div
        className="ombre-style d-flex justify-content-center border border-2 border-danger mt-lg-0 mt-4"
        style={{ width: "600px", borderRadius: "25px" }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center w-100 px-4">
          <img
            src="./assets/images/logo.svg"
            alt="logo"
            width="20%"
            className="my-3 d-lg-block d-none"
          />
          <h4 className="fw-bold text-center mb-0 fs-10 mt-2 mt-lg-0">
            Créer un nouvel utilisateur
          </h4>
          <p className="text-secondary text-center mx-4"></p>
          <Alert
            key="success"
            variant="success"
            className={
              !params.state.Logged
                ? "d-block py-0 px-1 text-center mb-1"
                : "d-none"
            }
            style={{ fontSize: "12px" }}
          >
            <LuMailCheck className="fs-5 me-2 text-success" />
            {params.state.message}
          </Alert>
          <Alert
            key="danger"
            variant="danger"
            className={ErrorConnexion !== "" ? "d-block py-0 mb-1" : "d-none"}
            style={{ fontSize: "12px" }}
          >
            {ErrorConnexion}
          </Alert>
          <Form>
              <Form.Group controlId="formPrenom" className="mb-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Label style={{ marginRight: '10px', fontWeight: 'bold' }}>Prénom</Form.Label>
                  <Form.Control
                    style={{ width: '300px' }}
                    type="text"
                    placeholder="Entrez votre prénom"
                    name="firstName"
                    onChange={handleInputChange}
                  />
                </div>
              </Form.Group>    
              <Form.Group controlId="formNom" className="mb-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Label style={{ marginRight: '10px', fontWeight: 'bold' }}>Nom</Form.Label>
                  <Form.Control
                    style={{ marginLeft: '20px', width: '300px' }}
                    type="text"
                    placeholder="Entrez votre nom de famille"
                    name="lastName"
                    onChange={handleInputChange}
                  />
                </div>
              </Form.Group>
            <Form.Group controlId="formEmail" className="mb-3">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Label style={{ marginRight: '10px', fontWeight: 'bold' }}>Email</Form.Label>
                <Form.Control
                  style={{ marginLeft: '20px', width: '300px' }}
                  type="email"
                  placeholder="Entrez votre adresse courriel"
                  name="email"
                  onChange={handleInputChange}
                />
              </div>
            </Form.Group>

            <Form.Group controlId="formCompany" className="mb-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Label style={{ marginRight: '10px', fontWeight: 'bold' }}>Entreprise</Form.Label>
                  <Form.Control value={params.state.company_name}
                    style={{ marginLeft: '20px', width: '265px' }}
                    type="text"
                    placeholder="Entreprise"
                    name="company"
                    onChange={handleInputChange} disabled
                  />
                </div>
              </Form.Group>

            <div className="d-flex justify-content-center"> {/* Centered div */}
              <div className="mb-4"> {/* Adjust margin-bottom as needed */}
                <Button variant="success" onClick={handleCreateUser} className="me-3">
                  Valider
                </Button>
                <Button variant="primary" onClick={handleReturn}>Retour</Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Alerts State={Alerte.State} Type={Alerte.Type} Title={Alerte.Title}
          Message={Alerte.Message} onClose={onCloseAlert} Duree={7} />
    </div>
  );
}
