//import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, createContext, useEffect, useRef } from "react";
import Header from "./Components/Commons/Header";
import "./Themes/custom.scss";
import Connection from "./Pages/Connection";
import VirtualCard from "./Pages/VirtualCard";
import { Route, Routes, Navigate } from "react-router-dom";
import {
  getSessionID,
  getSessionLogged,
  getSessionToken,
  setSessionLogged,
} from "./Components/Security/Session";
import Anonymous from "./Components/Security/Anonymous";
import Protected from "./Components/Security/Protected";
import { setSessionID, setSessionToken } from "./Components/Security/Session";
import { useNavigate } from "react-router-dom";
import ForgottenPassword from "./Pages/ForgottenPassword/ForgottenPassword";
import Registration from "./Pages/Users/Registration";
import Confirmation from "./Components/Commons/Confirmation";
import ResetForgotPassword from "./Pages/ForgottenPassword/ResetPassword";
import ChangePassword from "./Pages/Users/ChangePassword";
import CreateUser from "./Pages/Users/CreateUser";
import CompaniesList from "./Pages/Companies/CompaniesList";
import CompanyDetails from "./Pages/CompanyDetails";
import UserDetails from "./Pages/Users/UserDetails";
import AddCompany from "./Pages/Companies/AddCompany";
import UserList from "./Pages/Users/UsersList";
import UpdateUser from "./Pages/Users/UpdateUser";
import EditCompany from "./Pages/Companies/UpdateCompany";

export const sessionContext = createContext();
export const userContext = createContext();
export const permissions = createContext();


export default function App() {
  const navigate = useNavigate();
  const [session, setSession] = useState({ Logged: false, Token: "", Id: "" });
  const [user, setUser] = useState({
    Id: 0,
    Lastname: "",
    Firstname: "",
    Email: "",
  });

  let init = useRef(false);

  useEffect(() => {
    let isLogged = getSessionLogged();
    if (isLogged !== null) {
      let b = isLogged === "true" ? true : false;
      if (!init.current) {
        setSession({ Logged: b, Token: getSessionToken(), Id: getSessionID() });
        init.current = true;
      }
    }
  }, [session, setSession, navigate]);

  //------------------------------Authentification------------------------------
  function HandleLogin(sessions) {
    setSessionToken(sessions.Token);
    setSessionLogged(sessions.Logged);
    setSessionID(sessions.Id);
    setSession({ Logged: sessions.Logged, Token: sessions.Token });
    init.current = false;
  }

  return (
    <>
      <sessionContext.Provider value={[session, setSession]}>
        <userContext.Provider value={[user, setUser]}>
          <Header />
          <Routes>
            {/*---------------------------------------*/}
            {/*------Route acc�s au publique----------*/}
            {/*---------------------------------------*/}
            <Route path="/" element={
                <Anonymous Logged={session.Logged}>
                  <Connection onLogin={HandleLogin} />
                </Anonymous>
            } />
            <Route path="/Registration" element={
                <Anonymous Logged={session.Logged}>
                  <Registration />
                </Anonymous>
              } />
            <Route path="/ForgottenPassword" element={
                <Anonymous Logged={session.Logged}>
                  <ForgottenPassword />
                </Anonymous>
              } />
            <Route path="/ResetForgotPassword" element={
                <Anonymous Logged={session.Logged}>
                  <ResetForgotPassword />
                </Anonymous>
              } />

            {/*----------------------------------------*/}
            {/*------Route proteger par login----------*/}
            {/*---------------------------------------*/}
            <Route path="/VirtualCard" element={
                <Protected Logged={session.Logged}>
                  <VirtualCard />
                </Protected>
              } />
            <Route
              path="/UsersList"
              element={
                <Protected Logged={session.Logged}>
                  <UserList />
                </Protected>
              }
            />
            <Route path="/UserDetails" element={
                <Protected Logged={session.Logged}>
                  <UserDetails />
                </Protected>
              } />
            <Route path="/CompaniesList" element={
                <Protected Logged={session.Logged}>
                  <CompaniesList />
                </Protected>
              } />
            <Route path="/CompanyDetails" element={
                <Protected Logged={session.Logged}>
                  <CompanyDetails />
                </Protected>
              } />
            <Route path="/AddCompany" element={
                <Protected Logged={session.Logged}>
                  <AddCompany />
                </Protected>
              } />
            <Route path="/EditCompany" element={
                <Protected Logged={session.Logged}>
                  <EditCompany />
                </Protected>
              } />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route path="/Confirmation" element={<Confirmation />} />
            <Route path="*" element={
                session.Logged ? (
                  <Navigate to="/" replace />
                ) : (
                  <Navigate to="/" replace />
                )
              }/>
            <Route path="/CreateUser" element={<CreateUser />} />
            <Route path="/UpdateUser" element={
                <Protected Logged={session.Logged}>
                    <UpdateUser />
                </Protected>
              }
            />
            <Route path="/DeleteUser" element={
                <Protected Logged={session.Logged}>
                </Protected>
              }
            />
          </Routes>
        </userContext.Provider>
      </sessionContext.Provider>
    </>
  );
}
