import { useMutation } from "react-query";
import axios from "axios";
import querystring from "query-string";

//const urlBase = "http://127.0.0.1:8000/";
const urlBase = "http://apicartevirtuelle.rdr2510.xyz/";


//------------------------SUPPRIMER----------------------
export function DeleteUser() {
  const deleteUser = async ({ id, token }) => {
    const { data, error } = await axios.delete(urlBase + "api/auth/deleteuser", {
      params: { id: id },
      headers: { Authorization: "Bearer " + token, Accept: "application/json" },
    });
    return data || error;
  };
  return useMutation(deleteUser);
}

//------------------------SUPPRIMER----------------------
export function DeleteCompany() {
  const deleteCompany = async ({ id, token }) => {
    const { data, error } = await axios.delete(urlBase + "api/company/deletecompany/"+id, {
      headers: { Authorization: "Bearer " + token, Accept: "application/json" },
    });
    return data || error;
  };
  return useMutation(deleteCompany);
}

//------------------------AUTHENTIFIER----------------------
export function Login() {
  const authentification = async ({ email, password }) => {
    const { data, error } = await axios.get(urlBase + "api/auth/login", {
      params: { email: email, password: password },
      headers: { Accept: "application/json" },
    });
    return data || error;
  };
  return useMutation(authentification);
}

//------------------------DÉCONNECTER----------------------
export function Logout() {
  const disconnect = (token) => {
    return axios.get(urlBase + "api/auth/logout", {
      headers: { Authorization: "Bearer " + token, Accept: "application/json" },
    });
  };
  return useMutation(disconnect);
}

//------------------------DEMANDE MOT DE PASSE OUBLIER----------------------
export function RequestForgotPassword() {
  const requestForgotPassword = ({ email }) => {
    return axios.get(urlBase + "api/auth/forgotpasswordrequest", {
      params: { email: email },
    });
  };
  return useMutation(requestForgotPassword);
}

//--------------------------UPDATE USER---------------------
export function UserUpdate() {
    const updateUser = async({id, lastName, firstName, email, token, }) => {
        const { data, error } = await axios.put(urlBase + 'api/auth/userupdate/'+id,
            querystring.stringify({lastName: lastName, firstName: firstName, email: email,}), { 
            headers: {
                      'content-type': 'application/x-www-form-urlencoded',
                      Authorization: "Bearer " + token,
                      Accept: 'application/json',
                    },
                }    
        );
        return data || error;
    };
    return useMutation(updateUser);
}

//------------------------CHANGEMENT DE MOT DE PASSE OUBLIER----------------------
export function ResetPassword() {
  const ChangePassword = async ({
    email,
    code,
    new_password,
    new_password_confirmed,
  }) => {
    const { data, error } = await axios.post(
      urlBase + "api/auth/passwordreset",
      querystring.stringify({
        email: email,
        verification_code: code,
        new_password: new_password,
        new_password_confirmation: new_password_confirmed,
      }),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      }
    );
    return data || error;
  };
  return useMutation(ChangePassword);
}

//------------------------CHANGEMENT DE MOT DE PASSE----------------------
export function ChangePassword() {
  const ChangePassword = async ({
    email,
    password,
    new_password,
    new_password_confirmed,
  }) => {
    const { data, error } = await axios.post(
      urlBase + "api/auth/changepassword",
      querystring.stringify({
        email: email,
        password: password,
        new_password: new_password,
        new_password_confirmation: new_password_confirmed,
      }),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      }
    );
    return data || error;
  };
  return useMutation(ChangePassword);
}

//------------------------PROFILE----------------------
export const Profil = (token) => {
  return axios.get(urlBase + "api/auth/profile", {
    headers: { Authorization: "Bearer " + token, Accept: "application/json" },
  });
};

//------------------------DEMANDE MOT DE PASSE OUBLIER----------------------
export async function DetailCompany(id, token) {
      return await axios.get(urlBase + "api/company/detail/"+id, {
          headers: { Authorization: "Bearer " + token, Accept: "application/json" },
      });
}

//------------------------Registration NOUVEAU UTILISATEUR----------------------
export function RegisterUser() {
  const userRegistration = async ({ company_id, email, lastName, firstName, token }) => {
    const { data, error } = await axios.post(
        urlBase + "api/auth/register",
        querystring.stringify({company_id: company_id, email: email, lastName: lastName, firstName: firstName, token: token }),
        { headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token, "Content-Type": "application/x-www-form-urlencoded",
            }, 
        }
    );
    return data || error;
  };
  return useMutation(userRegistration);
}

//------------------------Registration NOUVELLE ENTREPRISE----------------------
export function RegisterCompany() {
  const CompanyRegister = async ({
    nom, logo, adresse, ville, code_postal, telephone, site_web,remise,
    email, firstName, lastName, token
  }) => {
    const { data, error } = await axios.post(urlBase + "api/company/entreprise",
      querystring.stringify({
        name: nom, logo: logo, address: adresse, city: ville,
        postal_code: code_postal, telephone: telephone, website: site_web,
        discount: remise,
        email: email, lastName: lastName, firstName: firstName,
        token: token
      }), {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token, "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return data || error;
  };
  return useMutation(CompanyRegister);
}

export function CompanyUpdate() {
  const companyUpdate = async ({id, 
    name, logo, address, city, postal_code, telephone, website, discount, token
  }) => {
    const { data, error } = await axios.put(urlBase + "api/company/edit/"+id,
      querystring.stringify({
        name: name, logo: logo, address: address, city: city,
        postal_code: postal_code, telephone: telephone, website: website,
        discount: discount, token: token
      }), {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token, "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return data || error;
  };
  return useMutation(companyUpdate);
}

//------------------------LISTE DES ENTREPRISES----------------------
export const CompaniesLists = async (token) => {
    return await axios.get(urlBase + "api/company/listentreprise", {
        headers: { Authorization: "Bearer " + token, Accept: "application/json" },
    });
};

//------------------------LISTE DES UTILISATEURS----------------------
export const UsersList = async (token) => {
  try {
    const response = await axios.get(urlBase + "api/auth/users", {
      headers: { Authorization: "Bearer " + token, Accept: "application/json" },
    });
 
    return response.data;
  } catch (error) {
    console.error("Error fetching user list:", error);
    throw error;
  }
};

export const ListUser = async (id, token) => {
  try {
      const response = await axios.get(urlBase + "api/auth/list/"+id, {
        headers: { Authorization: "Bearer " + token, Accept: "application/json" },
      });
      return response.data;
  } catch (error) {
      console.error("Error fetching user list:", error);
      throw error;
  }
};
 
//------------------------RECHERCHE UTILISATEUR PAR ID----------------------
export const SearchUser = async (token, id) => {
  try {
    const response = await axios.get(urlBase + "api/auth/usersearch", {
      headers: { Authorization: "Bearer " + token, Accept: "application/json" },
      params: { id: id },
    });
 
    return response.data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

