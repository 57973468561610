import "./Styles.css";
import React, { useEffect, useState } from "react";
import Spinners, { Alerts } from "../../Components/Commons/Common";
import { Form, InputGroup, Alert, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { BiSolidUser } from "react-icons/bi";
import { CiSaveDown2 } from "react-icons/ci";
import { MdPassword } from "react-icons/md";
import { GoNumber } from "react-icons/go";
import { LuMailCheck } from "react-icons/lu";
import { ResetPassword } from "../../Backend/api";
import { Link, useNavigate } from "react-router-dom";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

export default function ResetForgotPassword()
{
  const changePassword = ResetPassword();

  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [ErrorConnexion, setErrorConnexion] = useState("");
  const [Alerte, setAlerte] = useState({
    State: false,
    Title: "",
    Type: "",
    Message: "",
  });
  let [InputCode, setInputCode] = useState("");
  const [passwordView, setPasswordView] = useState(false);
  const [inputTypePassword, setInputTypePassword] = useState('password');

  function handlePasswordView()
{
    setPasswordView(!passwordView);    
  }

  useEffect(()=>{
    passwordView ? setInputTypePassword('text') : setInputTypePassword('password');
  }, [passwordView, setInputTypePassword]);


  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false)
{
      event.stopPropagation();
      setValidated(true);
    } else {
      changePassword.mutate({
        email: event.target.Email.value,
        code: event.target.Code.value,
        new_password: event.target.newPassword.value,
        new_password_confirmed: event.target.newPasswordConfirmed.value,
      });
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <ul className="text-start m-0 p-0">
        <li className="m-0">- La longueur minimale d'un mot de passe doit être de 8 caractères</li>
        <li className="m-0">- Il doit contenir au moins : </li>
        <ul className="text-start">          
          <li className="my-0">une lettre majuscule,</li>
          <li className="my-0">une lettre minuscule</li>
          <li className="my-0">un chiffre</li>
          <li className="my-0">un caractère spécial</li>
        </ul>
      </ul>
    </Tooltip>
  );

  function onFermerAlert()
  {
    setAlerte({ State: false });
  }

  function handleKeyUp(event)
  {
    if (event.target.value.length <= 6)
    {
      setInputCode(event.target.value);
    }
  }

  if (changePassword.isError)
  {
      console.log(changePassword.error.response);
      if (changePassword.error.response.data)
      {
          let m = "";
          if (changePassword.error.response.status === 403)
          {
              let new_password = changePassword.error.response.data.data["new_password"];
              if (new_password)
              {
                  for (let i = 0; i < new_password.length; ++i)
                  {
                      m = m + new_password[i] + "\n";
                  }
              }
              let verification_code = changePassword.error.response.data.data["verification_code"];
              if (verification_code)
              {
                  for (let i = 0; i < verification_code.length; ++i)
                  {
                      m = m + verification_code[i] + "\n";
                  }
              }
              let email = changePassword.error.response.data.data["email"];
              if (email)
              {
                  for (let i = 0; i < email.length; ++i)
                  {
                      m = m + email[i] + "\n";
                  }
              }
              let new_password_confirmation = changePassword.error.response.data.data["new_password_confirmation"];
              if (new_password_confirmation)
              {
                  for (let i = 0; i < new_password_confirmation.length; ++i)
                  {
                      m = m + new_password_confirmation[i] + "\n";
                  }
              }
          } else if (changePassword.error.response.status === 404)
          {
              m = changePassword.error.response.data.message;
          }
          setErrorConnexion(
            "Code: " +
              changePassword.error.response.status +
              " - " +
              changePassword.error.response.data.message
          );
          if (changePassword.error.response.data.data)
          {
              setAlerte({
                State: true,
                Title:  "Erreur - Code: " + changePassword.error.response.status,
                Type: "ERROR",
                Message: m,
              });
          }
      }
    changePassword.reset();
  } else if (changePassword.isSuccess)
  {
    changePassword.reset();
    navigate("/Confirmation", {
      state: {
        message: "Votre mot de passe a été changé avec succès !",
        description:
          "Veuillez vous connecter avec votre nom d'utilisateur et votre nouveau mot de passe.",
      },
    });
  }

  if (changePassword.isLoading)
  {
    return (
      <Spinners
        Message={
          "Changement de mot de passe en cours..., Veuillez patienter s'il-vous-plait."
        }
      />
    );
  }

  return (
    <>
      <div className="row d-flex justify-content-center mt-4">
        <div className="col-10 col-xs-8 col-sm-6 col-md-5 col-lg-4 col-xl-3">
          <div
            className="ombre-style d-flex flex-column align-items-center border border-2 border-danger px-3 px-lg-4 pb-4"
            style={{ borderRadius: "25px" }}
          >
            <img
              src="./assets/images/logo.svg"
              alt="logo"
              width="25%"
              className="my-3 d-lg-block d-none"
            />
            <h4 className="fw-bold text-center mb-0 fs-5 mt-2 mt-lg-0">
              Confirmation de changement de mot de passe
            </h4>
            <p className="text-secondary text-center mx-4"></p>
            <Alert
              key="success"
              variant="success"
              className="d-block py-0 px-1 text-center mb-2 mb-lg-3"
              style={{ fontSize: "12px" }}
            >
              <LuMailCheck className="fs-5 me-2 text-success" />
              Un courriel de cofirmation contenant le code de verification a été
              envoyé. Veuillez verifier votre courriel.
            </Alert>
            <Alert
              key="danger"
              variant="danger"
              className={
                ErrorConnexion !== "" ? "d-block py-0 mb-2 mb-lg-3" : "d-none"
              }
              style={{ fontSize: "12px" }}
            >
              {ErrorConnexion}
            </Alert>
            <Form
              id="form-connexion"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className="w-100 d-flex flex-column align-items-center mb-2"
            >
              <Form.Group controlId="Email" className="mb-2 mb-lg-3 px-0 w-100">
                <InputGroup hasValidation>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="bg-danger border border-1 border-danger"
                  >
                    <BiSolidUser className="fs-3 my-0 text-light" />
                  </InputGroup.Text>
                  <Form.Control
                    className="border border-1 border-danger"
                    type="email"
                    placeholder="Courriel"
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="mt-0"
                    style={{ fontSize: "12px" }}
                  >
                    L'adresse courriel est requise.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="Code" className="mb-2 mb-lg-3 px-0 w-100">
                <InputGroup hasValidation>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="bg-danger border border-1 border-danger"
                  >
                    <GoNumber className="fs-3 my-0 text-light" />
                  </InputGroup.Text>
                  <Form.Control
                    value={InputCode}
                    onChange={(event) => handleKeyUp(event)}
                    className="border border-1 border-danger"
                    type="number"
                    maxLength={6}
                    placeholder="Code de vérification"
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="mt-0"
                    style={{ fontSize: "12px" }}
                  >
                    Le code de vérification est obligatoire.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                <Form.Group
                  controlId="newPassword"
                  className="mb-2 mb-lg-3 px-0 w-100"
                >
                  <InputGroup hasValidation>
                    <InputGroup.Text
                      id="inputGroupPrepend"
                      className="bg-danger border border-1 border-danger"
                    >
                      <MdPassword className="fs-3 my-0 text-light" />
                    </InputGroup.Text>
                    <Form.Control
                      className="border border-1 border-danger"
                      type={inputTypePassword}
                      placeholder="Nouveau Mot de passe"
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <Button variant="outline-primary" onClick={()=>handlePasswordView()}>{passwordView ? <IoEyeOffOutline /> : <IoEyeOutline />}</Button>
                    <Form.Control.Feedback
                      type="invalid"
                      className="mt-0"
                      style={{ fontSize: "12px" }}
                    >
                      Le nouveau mot de passe est obligatoire.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </OverlayTrigger>
              <Form.Group
                controlId="newPasswordConfirmed"
                className="mb-2 mb-lg-3 px-0 w-100"
              >
                <InputGroup hasValidation>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="bg-danger border border-1 border-danger"
                  >
                    <MdPassword className="fs-3 my-0 text-light" />
                  </InputGroup.Text>
                  <Form.Control
                    className="border border-1 border-danger"
                    type={inputTypePassword}
                    placeholder="Confirmer le Mot de passe"
                    aria-describedby="inputGroupPrepend"
                    required
                  />                  
                  <Form.Control.Feedback
                    type="invalid"
                    className="mt-0"
                    style={{ fontSize: "12px" }}
                  >
                    La confirmation de mot de passe est obligatoire.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <div className="d-flex flex-column justify-content-center mt-3 mt-lg-4">
                <button
                  type="submit"
                  className="btn btn-danger mx-5 d-flex align-items-center justify-content-center"
                >
                  <CiSaveDown2 className="fs-2 me-2" />
                  Modifier
                </button>
              </div>
              <Link
                to="/"
                className="text-center mt-3"
                style={{ textUnderlineOffset: "5px" }}
              >
                Retour à la page de connexion
              </Link>
            </Form>
          </div>
        </div>
      </div>
      <Alerts
        State={Alerte.State}
        Type={Alerte.Type}
        Title={Alerte.Title}
        Message={Alerte.Message}
        onFermer={onFermerAlert}
        Duree={25}
      />
    </>
  );
}
