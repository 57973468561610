import { Button, Form, InputGroup } from "react-bootstrap";
import { FiPhoneCall } from "react-icons/fi";
import { SlEnvolopeLetter } from "react-icons/sl";
import { FaMapMarkerAlt, FaCity } from "react-icons/fa";
import { CiDiscount1 } from "react-icons/ci";
import { FaBuildingUser, FaComputer } from "react-icons/fa6";
import { MdOutlineAddHomeWork } from "react-icons/md";
import { useContext, useEffect, useRef, useState } from 'react';
import Spinners, { Alerts, Height100 } from "../../Components/Commons/Common";
import { sessionContext} from "../../App";
import { CompanyUpdate, DetailCompany } from "../../Backend/api";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegPenToSquare } from "react-icons/fa6";
import { useQuery } from "react-query";


export default function EditCompany() {
    const [validated, setValidated] = useState(false);
    const [CompanyValues, setCompanyValues] = useState({});
    const [discount, setDiscount] = useState('');
    const [loading, setLoading] = useState(false);
    const [refList, setRefList] = useState();
    let rList = useRef();
    const inputFileRef = useRef(null);
    const navigate = useNavigate();
    const [session] = useContext(sessionContext);
    const companyUpdate = CompanyUpdate();
    const [Alerte, setAlerte] = useState({State: false, Title: "", Type: "", Message: ""});
    const params = useLocation();

    rList= Height100(rList, 50);

    useEffect(()=>{
        const fetchUser = async () => {
            setLoading(true);
            const response =  await DetailCompany(params.state.id, session.Token)
            console.log(response);
            setCompanyValues(response.data.data);        
            setDiscount(response.data.data.discount);
            setLoading(false);
        }
        fetchUser();
        setRefList(rList.current);
    }, [rList, setRefList, setCompanyValues, setDiscount, params.state.id, session.Token])

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false)
        {
            event.stopPropagation();
            setValidated(true);
        } else {

            let values= {id: params.state.id, name: event.target.name.value,
                address: event.target.address.value,
                city: event.target.city.value,
                postal_code: event.target.postal_code.value,
                telephone: event.target.telephone.value,
                website: event.target.website.value,
                discount: event.target.discount.value,
                logo: event.target.logo.files,
                token: session.Token};
            companyUpdate.mutate(values);
        }
    };

    function handleKeyUp(event)
    {
        if (event.target.value.length <= 3)
        {
            setDiscount(event.target.value);
        }
    }

    function handleCancel() 
    {
        navigate('/CompaniesList');
    }

    function onCloseAlert() {
        setAlerte({ State: false });
        navigate('/CompaniesList');
    }

    if (companyUpdate.isError) {
        companyUpdate.reset();
        // Erreur générique
        setAlerte({State: true, Title: 'Erreur', Type: 'danger',
            Message: "Une erreur s'est produite lors de la communication avec le serveur. Veuillez réessayer.",
            Duree: 15,
        });
    }
  
    if (companyUpdate.isSuccess) {
        companyUpdate.reset();
        navigate("/Confirmation", {
            state: {
                message: "L'entreprise a été modifier avec succès !",
                Logged: session.Logged,
                linkReturn: "/CompaniesList",
                textReturn: "Retour à la page liste entreprise"
            },
        });
    }

    

    if (loading) {
        return (
            <Spinners Message={"Chargement des données de l'entreprise en cours..., Veuillez patienter s'il-vous-plaît."} />
        );
    } 

    if (companyUpdate.isLoading) {
        return (
            <Spinners Message={"Modification de l'entreprise en cours..., Veuillez patienter s'il-vous-plaît."} />
        );
    } 
  

    return (
        <>
            <div className="row d-flex justify-content-center mt-3">
                <div className="col-11">
                    <div ref={rList} className="ombre-style d-flex flex-column justify-content-evenly align-items-center border border-2 border-danger px-3 px-lg-5 pb-4" style={{ borderRadius: "25px" }}>                    
                        <h2 className="fw-bold text-center my-4"><MdOutlineAddHomeWork className='me-2'/>Modifier un entreprise</h2>
                        <Form className="w-100 d-flex flex-column align-items-center mb-2" noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className='d-flex flex-lg-row flex-column justify-content-center w-100'>
                                <div className='mx-2'>
                                    <Form.Group controlId="name" className="mb-2 mb-lg-3 px-0">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger" >
                                                <FaBuildingUser className="fs-3 my-0 text-light" />
                                            </InputGroup.Text>
                                            <Form.Control defaultValue={CompanyValues?CompanyValues.name:''} className="border border-1 border-danger" type="text" placeholder="Nom de l'entreprise" aria-describedby="inputGroupPrepend" required />
                                            <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} >
                                                Le champ nom est obligatoire.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group controlId="address" className="mb-2 mb-lg-3 px-0">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger">
                                                <FaMapMarkerAlt className="fs-3 my-0 text-light" />
                                            </InputGroup.Text>
                                            <Form.Control defaultValue={CompanyValues?CompanyValues.address:''} className="border border-1 border-danger" type="tel" placeholder="Adresse" aria-describedby="inputGroupPrepend" required/>
                                            <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} >
                                                L'adresse est requise
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <div className="d-flex flex-column flex-lg-row">
                                        <Form.Group controlId="city" className="mb-2 mb-lg-3 me-0 me-lg-2 px-0">
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger" >
                                                    <FaCity className="fs-3 my-0 text-light" />
                                                </InputGroup.Text>
                                                <Form.Select className="border border-1 border-danger" placeholder="Ville" aria-describedby="inputGroupPrepend" required >
                                                    <option disabled>Selectionner la ville ...</option>
                                                    <option value="1">Saint Jean-sur-Richelieu</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} >
                                                    Le nom de la ville est requis
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group controlId="postal_code" className="mb-2 mb-lg-3 px-0" >
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger" >
                                                    <SlEnvolopeLetter className="fs-3 my-0 text-light" />
                                                </InputGroup.Text>
                                                <Form.Control defaultValue={CompanyValues?CompanyValues.postal_code:''} className="border border-1 border-danger" type="text" placeholder="Code postal" aria-describedby="inputGroupPrepend" required />
                                                <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} >
                                                    Le code postal est requis
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className='mx-2'>
                                    <Form.Group controlId="website" className="mb-2 mb-lg-3 px-0" >
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger" >
                                                <FaComputer className="fs-3 my-0 text-light" />
                                            </InputGroup.Text>
                                            <Form.Control defaultValue={CompanyValues?CompanyValues.website:''} className="border border-1 border-danger" type="text" placeholder="Site Web" aria-describedby="inputGroupPrepend" required/>
                                            <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }}>
                                                L'adresse web est requise
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>

                                    <div className="d-flex flex-column flex-lg-row">
                                        <Form.Group controlId="telephone" className="mb-2 me-0 me-lg-2 mb-lg-3 px-0" >
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger">
                                                    <FiPhoneCall className="fs-3 my-0 text-light" />
                                                </InputGroup.Text>
                                                <Form.Control defaultValue={CompanyValues?CompanyValues.telephone:''} className="border border-1 border-danger" type="tel" placeholder="Téléphone" aria-describedby="inputGroupPrepend" required/>
                                                <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} >
                                                    Le numéro de téléphone est requis
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group controlId="discount" className="mb-2 mb-lg-3 px-0" >
                                            <InputGroup hasValidation>
                                                <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger">
                                                    <CiDiscount1 className="fs-3 my-0 text-light" />
                                                </InputGroup.Text>
                                                <Form.Control className="border border-1 border-danger" type="number" maxLength={3} placeholder="Remise" 
                                                    value={discount} onChange={(event) => handleKeyUp(event)}
                                                    aria-describedby="inputGroupPrepend" required />
                                                <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} >
                                                    La remise est requise
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                    </div>

                                    <Form.Group className="mb-2 mb-lg-3 px-0" controlId="logo">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend" className="px-2 bg-danger rounded-0 border border-1 border-danger rounded-start" >
                                                <span className="fs-6 my-0 text-light">Logo</span>
                                            </InputGroup.Text>
                                            <Form.Control ref= {inputFileRef} className="border border-1 border-danger" type="file" placeholder="Logo" aria-describedby="inputGroupPrepend" />
                                            <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }} >
                                                Le Logo est requise
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="d-flex flex-column flex-lg-row justify-content-evenly mt-4 w-100">
                                <Button variant="secondary" onClick={handleCancel} className="me-3">Annuler</Button>
                                <Button variant="success" type="submit" className="me-3"><FaRegPenToSquare className="fs-4 me-0 me-lg-2"/>Mise à jour</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <Alerts State={Alerte.State} Type={Alerte.Type} Title={Alerte.Title} Message={Alerte.Message} onClose={onCloseAlert} Duree={7} />
        </>
    );
}