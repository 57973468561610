import "./Styles.css";
import React, { useState, useContext, useEffect } from "react";
import Spinners, { Alerts } from "../../Components/Commons/Common";
import { Form, InputGroup, Button, OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import { BiSolidUser } from "react-icons/bi";
import { CiSaveDown2 } from "react-icons/ci";
import { MdPassword } from "react-icons/md";
import { LuMailCheck } from "react-icons/lu";
import { ChangePassword } from "../../Backend/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { sessionContext } from "../../App";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

export default function UpdatePassword() 
{
  const changePassword = ChangePassword();
  const [session] = useContext(sessionContext);

  const params = useLocation();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [ErrorConnexion, setErrorConnexion] = useState("");
  const [Alerte, setAlerte] = useState({
    State: false,
    Title: "",
    Type: "",
    Message: "",
  });
  const [passwordView, setPasswordView] = useState(false);
  const [newPasswordView, setNewPasswordView] = useState(false);
  const [inputTypePassword, setInputTypePassword] = useState('password');
  const [inputTypeNewPassword, setInputTypeNewPassword] = useState('password');

  function handlePasswordView() 
  {
    setPasswordView(!passwordView);
  }

  function handleNewPasswordView() 
  {
    setNewPasswordView(!newPasswordView);
  }

  useEffect(() => {
    passwordView ? setInputTypePassword('text') : setInputTypePassword('password');
    newPasswordView ? setInputTypeNewPassword('text') : setInputTypeNewPassword('password');
  }, [passwordView, setInputTypePassword, newPasswordView, setInputTypeNewPassword]);


  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      changePassword.mutate({
        email: event.target.Email.value,
        password: event.target.Password.value,
        new_password: event.target.newPassword.value,
        new_password_confirmed: event.target.newPasswordConfirmed.value,
      });
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <ul className="text-start m-0 p-0">
        <li className="m-0">- La longueur minimale d'un mot de passe doit être de 8 caractères</li>
        <li className="m-0">- Il doit contenir au moins : </li>
        <ul className="text-start">
          <li className="my-0">une lettre majuscule,</li>
          <li className="my-0">une lettre minuscule</li>
          <li className="my-0">un chiffre</li>
          <li className="my-0">un caractère spécial</li>
        </ul>
      </ul>
    </Tooltip>
  );

  function onCloseAlert() 
  {
    setAlerte({ State: false });
  }

  if (changePassword.isError) {
    if (changePassword.error.response.data) {
      let m = "";
      if (changePassword.error.response.status === 401) {
        if (changePassword.error.response.data.errors) {
          let email = changePassword.error.response.data.errors["email"];
          if (email) {
            for (let i = 0; i < email.length; ++i) {
              m = m + email[i] + "\n";
            }
          }

          let password = changePassword.error.response.data.errors["password"];
          if (password) {
            for (let i = 0; i < password.length; ++i) {
              m = m + password[i] + "\n";
            }
          }

          let new_password =
            changePassword.error.response.data.errors["new_password"];
          if (new_password) {
            for (let i = 0; i < new_password.length; ++i) {
              m = m + new_password[i] + "\n";
            }
          }

          let new_password_confirmation =
            changePassword.error.response.data.errors[
            "new_password_confirmation"
            ];
          if (new_password_confirmation) {
            for (let i = 0; i < new_password_confirmation.length; ++i) {
              m = m + new_password_confirmation[i] + "\n";
            }
          }
        }
      } else if (changePassword.error.response.status === 404) {
        m = changePassword.error.response.data.message;
      }
      setErrorConnexion(
        "Code: " +
        changePassword.error.response.status +
        " - " +
        changePassword.error.response.data.message
      );
      if (m !== "") {
        setAlerte({
          State: true,
          Title: "Erreur - Code: " + changePassword.error.response.status,
          Type: "ERROR",
          Message: m,
        });
      }
    }
    changePassword.reset();
  } else if (changePassword.isSuccess) {
    changePassword.reset();
    console.log("Success");
    navigate("/Confirmation", {
      state: {
        message: "Votre mot de passe a été changé avec succès !",
        linkReturn:'/',
        description: "Veuillez vous connecter avec votre nom d'utilisateur et votre nouveau mot de passe.",
        Logged: session.Logged,
      },
    });
  }

  if (changePassword.isLoading) {
    return (
      <Spinners
        Message={
          "Changement de mot de passe en cours..., Veuillez patienter s'il-vous-plaît."
        }
      />
    );
  }

  return (
    <div
      className="d-flex justify-content-center align-items-start px-5 overflow-y-auto py-lg-4"
      style={{ height: "100vh" }}
    >
      <div
        className="ombre-style d-flex justify-content-center border border-2 border-danger mt-lg-0 mt-4"
        style={{ width: "400px", borderRadius: "25px" }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center w-100 px-4">
          <img
            src="./assets/images/logo.svg"
            alt="logo"
            width="25%"
            className="my-3 d-lg-block d-none"
          />
          <h4 className="fw-bold text-center mb-0 fs-5 mt-2 mt-lg-0">
            Changement de mot de passe
          </h4>
          <p className="text-secondary text-center mx-4"></p>
          <Alert
            key="success"
            variant="success"
            className={
              !params.state.Logged
                ? "d-block py-0 px-1 text-center mb-1"
                : "d-none"
            }
            style={{ fontSize: "12px" }}
          >
            <LuMailCheck className="fs-5 me-2 text-success" />
            {params.state.message}
          </Alert>
          <Alert
            key="danger"
            variant="danger"
            className={ErrorConnexion !== "" ? "d-block py-0 mb-1" : "d-none"}
            style={{ fontSize: "12px" }}
          >
            {ErrorConnexion}
          </Alert>
          <Form
            id="form-connexion"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="w-100"
          >
            <div className="groupe-form border border-2 border-primary rounded rounded-3 p-2 my-3 pt-4">
              <span className="label">Identification</span>
              <Form.Group controlId="Email" className="mb-2 px-0">
                <InputGroup hasValidation>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="bg-danger border border-1 border-danger"
                  >
                    <BiSolidUser className="fs-3 my-0 text-light" />
                  </InputGroup.Text>
                  <Form.Control
                    disabled={params.state.Logged ? true : false}
                    defaultValue={
                      params.state.Email === "" ? "" : params.state.Email
                    }
                    className="border border-1 border-danger"
                    type="email"
                    placeholder="Courriel"
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="mt-0"
                    style={{ fontSize: "12px" }}
                  >
                    L'adresse courriel est requise.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="Password" className="px-0">
                <InputGroup hasValidation>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="bg-danger border border-1 border-danger"
                  >
                    <MdPassword className="fs-3 my-0 text-light" />
                  </InputGroup.Text>
                  <Form.Control
                    className="border border-1 border-danger"
                    type={inputTypePassword}
                    placeholder={
                      params.state.Logged
                        ? "Mot de passe actuel"
                        : "Mot de passe"
                    }
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                  <Button variant="outline-primary" onClick={() => handlePasswordView()}>{passwordView ? <IoEyeOffOutline /> : <IoEyeOutline />}</Button>
                  <Form.Control.Feedback
                    type="invalid"
                    className="mt-0"
                    style={{ fontSize: "12px" }}
                  >
                    {params.state.Logged
                      ? "Le mot de passe actuel est obligatoire"
                      : "Le mot de passe est obligatoire."}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>

            <div className="groupe-form border border-2 border-primary rounded rounded-3 p-2 pt-4 mt-5">
              <span className="label">Nouveau mot de passe</span>

              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                <Form.Group controlId="newPassword" className="mb-2 px-0">
                  <InputGroup hasValidation>
                    <InputGroup.Text
                      id="inputGroupPrepend"
                      className="bg-danger border border-1 border-danger"
                    >
                      <MdPassword className="fs-3 my-0 text-light" />
                    </InputGroup.Text>
                    <Form.Control
                      className="border border-1 border-danger"
                      type={inputTypeNewPassword}
                      placeholder="Nouveau Mot de passe"
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <Button variant="outline-primary" onClick={() => handleNewPasswordView()}>{newPasswordView ? <IoEyeOffOutline /> : <IoEyeOutline />}</Button>
                    <Form.Control.Feedback
                      type="invalid"
                      className="mt-0"
                      style={{ fontSize: "12px" }}
                    >
                      Le nouveau mot de passe est obligatoire.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </OverlayTrigger>
              <Form.Group controlId="newPasswordConfirmed" className="px-0">
                <InputGroup hasValidation>
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="bg-danger border border-1 border-danger"
                  >
                    <MdPassword className="fs-3 my-0 text-light" />
                  </InputGroup.Text>
                  <Form.Control
                    className="border border-1 border-danger"
                    type={inputTypeNewPassword}
                    placeholder="Confirmer le Mot de passe"
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="mt-0"
                    style={{ fontSize: "12px" }}
                  >
                    La confirmation de mot de passe est obligatoire.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>
            <div
              className={
                !params.state.Logged
                  ? "d-flex flex-column justify-content-center mt-3 mt-lg-4"
                  : "d-flex flex-column justify-content-center mt-3 mt-lg-4 mb-4"
              }
            >
              <button
                type="submit"
                className="btn btn-danger mx-5 d-flex align-items-center justify-content-center"
              >
                <CiSaveDown2 className="fs-2 me-2" />
                Modifier
              </button>
            </div>
            <Link
              to="/"
              className={
                !params.state.Logged
                  ? "d-flex justify-content-center w-100 mt-2 mb-3 mb-lg-4"
                  : "d-none"
              }
              style={{ textUnderlineOffset: "5px" }}
            >
              Connecter
            </Link>
          </Form>
        </div>
      </div>
      <Alerts
        State={Alerte.State}
        Type={Alerte.Type}
        Title={Alerte.Title}
        Message={Alerte.Message}
        onFermer={onCloseAlert}
        Duree={25}
      />
    </div>
  );
}
