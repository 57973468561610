import { Form, InputGroup, Alert, Button } from "react-bootstrap";
import "./styles.css";
import React, { useEffect, useState } from "react";
import {
  BiSolidUser,
  BiSolidUserCircle,
  BiSolidLogInCircle,
} from "react-icons/bi";
import { MdPassword } from "react-icons/md";
import Spinners, { Alerts } from "../Components/Commons/Common";
import { Login } from "../Backend/api";
import { Link, useNavigate } from "react-router-dom";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

export default function Connection({ onLogin })
{
  const auth = Login();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [Alerte, setAlerte] = useState({
    State: false,
    Title: "",
    Type: "",
    Message: "",
  });
  const [ErrorConnexion, setErrorConnexion] = useState("");
  const [Sessions, setSessions] = useState({
    Logged: false,
    Token: "",
    Id: "",
  });
  const [passwordView, setPasswordView] = useState(false);
  const [inputTypePassword, setInputTypePassword] = useState('password');

  function handlePasswordView()
{
    setPasswordView(!passwordView);    
  }

  useEffect(()=>{
    passwordView ? setInputTypePassword('text') : setInputTypePassword('password');
  }, [passwordView, setInputTypePassword]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false)
    { 
      event.stopPropagation();
      setValidated(true);
    } else {
      Sessions.Id = event.target.Email.value;
      setSessions(Sessions);
      auth.mutate({
        email: event.target.Email.value,
        password: event.target.Password.value,
      });
    }
  };

  if (auth.isError)
  {
    if (auth.error.response.status === 428)
    {
      navigate("/ChangePassword", {
        state: {
          message:
            "Code: " +
            auth.error.response.status +
            " - " +
            auth.error.response.data.message,
          Logged: false,
          Email: "",
        },
      });
    } else if (auth.error.response.data)
    {
      console.log(auth.error.response)
      setErrorConnexion(
        "Code: " +
          auth.error.response.status +
          " - " +
          (auth.error.response.data.data !== '' ? auth.error.response.data.data + " - " : '') 
          +
          auth.error.response.data.message
      );
    }
    auth.reset();
  } else if (auth.isSuccess)
  {
    console.log(auth.data);
    auth.reset();
    Sessions.Logged = true;
    Sessions.Token = auth.data.data;
    setSessions(Sessions);
    onLogin(Sessions);
  }

  function onCloseAlert()
  {
    setAlerte({ State: false });
  }

  if (auth.isLoading)
  {
      return (
        <Spinners Message={"Connexion en cours..., Veuillez patienter s'il-vous-plaît."} />
      );
  }

  return (
    <>
      <div
        className="row d-flex justify-content-center align-items-center"
        style={{ height: "90vh" }}
      >
        <div className="col-9 col-md-8 col-lg-8 col-xl-6">
          <div
            id="connexion"
            className="ombre-style d-flex justify-content-center mt-lg-0 mt-4"
            style={{ borderRadius: "25px" }}
          >
            <div
              id="gauche-connexion"
              className="bg-primary w-100 d-none d-lg-flex justify-content-center align-items-center p-5"
              style={{
                borderTopLeftRadius: "23px",
                borderBottomLeftRadius: "23px",
              }}
            >
              <img
                src="./assets/images/logo.svg"
                alt="logo"
                width="100%"
                className="px-5"
              />
            </div>
            <div
              id="droite-connexion"
              className="d-flex flex-column align-items-center justify-content-center w-75"
            >
              <BiSolidUserCircle
                className="text-danger mt-2"
                style={{ fontSize: "4rem" }}
              />
              <h4 className="mt-1 fw-bold text-primary">Se connecter</h4>
              <p className="text-secondary text-center mx-0 mx-lg-4">
                Connectez-vous avec les identifiants reçus par courriel.
              </p>
              <Alert
                key="danger"
                variant="danger"
                className={
                  ErrorConnexion !== ""
                    ? "d-block py-0 px-1 mx-0 mx-lg-3 text-center"
                    : "d-none"
                }
                style={{ fontSize: "0.8rem" }}
              >
                {ErrorConnexion}
              </Alert>
              <Form id="form-connexion" noValidate validated={validated} onSubmit={handleSubmit} className="d-flex flex-column align-items-center mb-3" >
                <Form.Group controlId="Email" className="mb-2 mb-lg-3 px-lg-4 px-0 w-100">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger" >
                      <BiSolidUser className="fs-3 my-0 text-light" />
                    </InputGroup.Text>
                    <Form.Control className="border border-1 border-danger" type="email" placeholder="Courriel" aria-describedby="inputGroupPrepend" required/>
                    <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }}>
                        Le nom d'utilisateur ou l'adresse courriel est requis.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="Password" className="px-lg-4 px-0">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend" className="bg-danger border border-1 border-danger">
                      <MdPassword className="fs-3 my-0 text-light" />
                    </InputGroup.Text>
                    <Form.Control className="border border-1 border-danger" type={inputTypePassword} placeholder="Mot de passe" aria-describedby="inputGroupPrepend" required />
                    <Button variant="outline-primary" onClick={()=>handlePasswordView()}>{passwordView ? <IoEyeOffOutline /> : <IoEyeOutline />}</Button>
                    <Form.Control.Feedback type="invalid" className="mt-0" style={{ fontSize: "0.7rem" }}>
                        Le mot de passe est obligatoire.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <div className="d-flex flex-column justify-content-center mt-4">
                    <button type="submit" className="btn btn-danger mx-lg-5 mx-2 d-flex align-items-center justify-content-center">
                        <BiSolidLogInCircle className="fs-2 me-2" />
                        Connexion
                    </button>
                </div>

                <Link to="/ForgottenPassword" className="mb-1 mt-3 text-danger" style={{ textUnderlineOffset: "5px" }}>
                    Mot de passe oublié?
                </Link>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Alerts
        State={Alerte.State}
        Type={Alerte.Type}
        Title={Alerte.Title}
        Message={Alerte.Message}
        onClose={onCloseAlert}
      />
    </>
  );
}
