import {
  Badge,
  Button,
  Form,
  Pagination,
  Table,
} from "react-bootstrap";
 
import { BsEyeFill, BsPencilSquare, BsSearch } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import { GoPersonAdd } from "react-icons/go";
import { FaUsers } from "react-icons/fa";
import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { sessionContext } from "../../App";
import { DeleteUser, ListUser, UsersList } from "../../Backend/api";
import { Spinner } from "react-bootstrap";
import Spinners, { Alerts } from "../../Components/Commons/Common";
import ModalConfirmation from "../../Components/Commons/ModalDlg";
 
export default function UserList() {
  const [session] = useContext(sessionContext);
  const [userList, setUserList] = useState([]);
  const navigate = useNavigate();
  const params = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [filteredUserList, setFilteredUserList] = useState(userList);
  const [searchInput, setSearchInput] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [loading, setLoading] = useState(true);
  const [Alerte, setAlerte] = useState({ State: false, Title: "", Type: "", Message: "", Duree: 15,});
  const [modalConfirm, setModalConfirm] = useState({
    Action: -1,
    State: false,
    Title: "",
    Message: "",
    TxtBtnConfirm: "",
    TxtBtnCancel: "",
    Type: "",
    userId: 0
  });
 
 
  useEffect(() =>
  {
    const fetchUserList = async () => {
      try {
          setLoading(true); // Démarre le chargement
          const UsersListData = await ListUser(params.state.id, session.Token);
          setUserList(UsersListData.data);
          setTotalUsers(UsersListData.data.length);
      } catch (error) {
          console.error("Error setting user list:", error);
      } finally {
          setLoading(false); // Termine le chargement
      }
    };
 
    fetchUserList();
  }, [session.Token, params.state.id]);
 
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
 
  useEffect(() =>
  {
    setFilteredUserList(userList);
  }, [userList]);
 
 
  const currentItems = filteredUserList.slice(indexOfFirstItem, indexOfLastItem);
 
  const handleSearch = () => {
    const filteredList = userList.filter(
      (user) =>
        user.lastName.toLowerCase().includes(searchInput.toLowerCase()) ||
        user.firstName.toLowerCase().includes(searchInput.toLowerCase()) ||
        user.email.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredUserList(filteredList);
    setTotalUsers(filteredList.length);
    setCurrentPage(1);
  };
 
  const updateUser = (userId) => {
    navigate("/UpdateUser", {
      state: {
        message: "",
        Logged: session.Logged,
        Email: session.Id,
        userId: userId,
      },
    });
  };
 
  const userDetails = (userId) => {
    navigate("/UserDetails", {
      state: {
        message: "",
        Logged: session.Logged,
        Email: session.Id,
        userId: userId,
      },
    });
  };

  function onCloseAlert()
  {
    setAlerte({ State: false });
    if (Alerte.Type === 'success') {
        navigate('/UsersList');
    }
  }

  function HandleModalConfirm(userId)
  {
    setModalConfirm({State: false});
    userDelete.mutate({id: userId, token: session.Token});
  }

  function HandleModalCancel()
  {
    setModalConfirm({State: false});
  }
 

  const userDelete = DeleteUser();

  const deleteUser = (userId) => {
    setModalConfirm({State: true, Type: "ERROR", Title:  "SUPPRESSION UTILISATEUR",
            Message: "Voulez-vous vraiment supprimer cet utilisateur ?", TxtBtnConfirm: "Suprrimer", TxtBtnCancel: "Annuler", userId: userId});
  };

  if (userDelete.isError) {
      userDelete.reset();
        // Erreur générique
        setAlerte({State: true, Title: 'Erreur', Type: 'danger',
            Message: "Une erreur s'est produite lors de la communication avec le serveur. Veuillez réessayer.",
            Duree: 15,
        });
  }

  if (userDelete.isSuccess) {
      userDelete.reset();
      navigate("/Confirmation", {
        state: {
            message: "L'utilisateur a été supprimé avec succès !",
            /*description: "Un courriel contenant le mot de passe temporaire de l'administrateur a été envoyé",*/
            Logged: session.Logged,
            linkReturn: "/UsersList",
            textReturn: "Retour à la page liste utilisateur"
        },
      });
  }

  if (userDelete.isLoading) {
      return (
          <Spinners Message={"Suppression utilisateur en cours..., Veuillez patienter s'il-vous-plaît."} />
      );
  }

  return (
 
    <div className="p-4">
      <div
        className="w-100 fw-bold d-flex justify-content-between"
        style={{ borderBottom: "1px solid red" }}
      >
        <h2 className="w-100 mb-0 fw-bold d-flex align-items-center">
          <FaUsers className="me-2 fs-1 text-danger" />
          Utilisateurs
        </h2>
 
        <Badge className="bg-warning mb-1 me-2 d-flex align-items-center fw-bold fs-4 py-1">
          Total: <Badge bg="secondary ms-2 fs-5">{totalUsers}</Badge>
        </Badge>
 
        <div className="d-flex mb-1">
          <Form.Control
            type="text"
            placeholder="Entrer votre recherche ici..."
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              width: "225px",
            }}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
 
          <Button
            type="submit"
            className="btn-success border-1 border-success"
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            onClick={() => handleSearch()}
          >
            <BsSearch className="fs-3" />
          </Button>
 
        </div>
      </div>
 
      {loading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Chargement...</span>
          </Spinner>
        </div>
      ) : (
        <>
 
          <div className="mt-4 w-100 d-flex justify-content-between">
            <Button
              onClick={() => {
                navigate("/CreateUser", {
                  state: {
                    message: "",
                    Logged: session.Logged,
                    company_id: params.state.id,
                    company_name: params.state.name
                  },
                });
              }}
              className="btn btn-primary"
            >
              <GoPersonAdd className="fs-5 fw-bold me-2" />
              Ajouter un nouvel utilisateur
            </Button>
            <h4 className="fw-bold">{params.state.name}</h4>
          </div>
          <Table
            responsive
            striped
            hover
            variant="light"
            className="mt-4"
          >
            <thead>
              <tr style={{ borderBottom: "2px solid black" }}>
                <th className="pb-0 text-center">#</th>
                <th className="pb-0 text-center">Nom</th>
                <th className="pb-0 text-center">Prénom</th>
                <th className="pb-0 text-center">Email</th>
                <th className="pb-0 text-center">Date de création</th>
                <th className="pb-0 text-center">Dernière modification</th>
                <th className="pb-0 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((user, index) => (
                <tr key={user.id} >
                  <td className="align-middle text-center">{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                  <td className="align-middle text-center">{user.lastName}</td>
                  <td className="align-middle text-center">{user.firstName}</td>
                  <td className="align-middle text-center">{user.email}</td>
                  <td className="align-middle text-center">{user.created_at}</td>
                  <td className="align-middle text-center">{user.updated_at}</td>
 
                  <td className="align-middle text-center">
 
                    <Button
                      variant="primary"
                      className="me-2"
                      onClick={() => userDetails(user.id)}
                    >
                      <BsEyeFill className="me-2" />
                      Voir
                    </Button>
                    <Button
                      variant="secondary"
                      className="me-2"
                      onClick={() => updateUser(user.id)}
                    >
                      <BsPencilSquare className="me-2" />
                      Modifier
                    </Button>
                    <Button
                      variant="danger"
                      className="text-white"
                      onClick={() => deleteUser(user.id)}
                    >
                      <ImCancelCircle className="me-2" />
                      Supprimer
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {filteredUserList.length === 0 ? (
            <div className="text-center mt-4 error-message">
              <p>Aucun utilisateur n’a été trouvé</p>
            </div>
          ) : (
 
            <div className="w-100 d-flex justify-content-center">
              <Pagination>
                {Array.from({ length: Math.ceil(totalUsers / itemsPerPage) }, (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          )}
        </>
      )}
      <Alerts
    State={Alerte.State}
    Type={Alerte.Type}
    Title={Alerte.Title}
    Message={Alerte.Message}
    Duree={Alerte.Duree}
    onClose={onCloseAlert}
  />
  <ModalConfirmation
        State={modalConfirm.State}
        Title={modalConfirm.Title}
        Message={modalConfirm.Message}
        ConfirmTextBtn={modalConfirm.TxtBtnConfirm}
        CancelTextBtn={modalConfirm.TxtBtnCancel}
        Type={modalConfirm.Type}
        onConfirm={HandleModalConfirm}
        onCancel={HandleModalCancel}
        data={modalConfirm.userId}
      />
    </div>
    
  );

}
 