import React from "react";
import "./Styles.css";
import { useLocation, Link } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";

export default function Confirmation() 
{
  const params = useLocation();

    console.log(params);
  return (
    <div className="d-flex justify-content-center align-items-start py-lg-4 px-5" style={{ height: "100vh" }}>
        <div className="ombre-style d-flex justify-content-center border border-2 border-danger mt-lg-0 mt-4" style={{ borderRadius: "25px" }} >
            <div className="d-flex flex-column align-items-center justify-content-center w-75">
                <BsFillCheckCircleFill className="text-success my-4" style={{ fontSize: "50px" }} />
                <h4 className="mt-1 fw-bold text-center lh-sm">{params.state.message}</h4>
                <p className={!params.state.Logged ? "text-secondary text-center mx-2 mt-4" : "text-secondary text-center m-4" } >
                    {params.state.description}
                </p>
                <div className={params.state.linkReturn ? "d-flex flex-column justify-content-center mb-4 mt-2" : "d-none"}>
                    <Link to={!params.state.linkReturn ? '/' : params.state.linkReturn} className="mb-1 mt-3 text-danger fw-medium" style={{ textUnderlineOffset: "5px", fontSize: "1.2rem" }} >
                        {!params.state.textReturn ? "Retour à la page de connexion" : params.state.textReturn}
                    </Link>
                </div>
            </div>
        </div>
    </div>
  );
}
